import PropTypes from "prop-types";
import React from "react";
import { Switch } from "react-router-dom";

import ProtectedRouteWithPageAnalytics from "~app/ProtectedRouteWithPageAnalytics";
import { useFeatureFlags } from "~app/Providers/FeatureFlagProvider";
import routes from "~app/routesConfig";
import RouteWithPageAnalytics from "~app/RouteWithPageAnalytics";
import { useCurrentCompany } from "~graphql/hooks/currentCompany";
import { useIsInternalUser } from "~graphql/hooks/users";

export default function Routes() {
    const { featureFlags } = useFeatureFlags();
    const { companyFeatureGroups } = useCurrentCompany();
    const { isInternalUser } = useIsInternalUser();

    return (
        <Switch>
            {routes.map((page) => {
                const { isPublic, featureFlagKey, requiredFeatureGroup } = page;

                const RouteComp = isPublic
                    ? RouteWithPageAnalytics
                    : ProtectedRouteWithPageAnalytics;
                const shouldRender = shouldShowRoute({
                    isInternalUser,
                    featureFlags,
                    featureFlagKey,
                    companyFeatureGroups,
                    requiredFeatureGroup,
                });

                if (!shouldRender) return null;

                return (
                    // Disable eslint rule for line as `key` comes from page
                    // eslint-disable-next-line react/jsx-key
                    <RouteComp {...page} />
                );
            })}
        </Switch>
    );
}

Routes.propTypes = {
    isAuthenticated: PropTypes.bool,
};

// if route does not have feature flag name config or feature flag name defined in route config is enabled, include in routes
// If company is not subscribed to the company feature required by the route, don't include in routes.
// Feature flags and company features/featureGroups are not related. Features and feature grooups are based on company subscriptions.
function shouldShowRoute({
    isInternalUser,
    featureFlags,
    featureFlagKey,
    companyFeatureGroups = [],
    requiredFeatureGroup,
}) {
    // If route doesn't have a featureFlag or feature flag is enabled.
    const isFeatureEnabled =
        !featureFlagKey || featureFlags?.[featureFlagKey] === true;

    // If route has a requiredFeatureGroup and user is not an internal admin.
    // Internal admin bypass all feature group requirements.
    const isFeatureGroupRequired = !isInternalUser && requiredFeatureGroup;

    // If feature group is not required or if user's company has the required feature group.
    const isAllowedToAccessFeature =
        !isFeatureGroupRequired ||
        companyFeatureGroups.includes(requiredFeatureGroup);

    return isFeatureEnabled && isAllowedToAccessFeature;
}
