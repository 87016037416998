import styled from "@emotion/styled";
import MuiDrawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import React from "react";
import { useLocation } from "react-router-dom";

import { useScreenSize } from "~app/hooks/utilHooks";
import SideBarContent from "~shared/SideBar/SideBarContent";

import { TEST_IDS, ROUTES } from "~constants";

export default function SideBar({ isOpen, setIsOpen }) {
    const { isMdScreen, isStreamMdScreen } = useScreenSize();
    const { pathname } = useLocation();
    const isMessagesPage = pathname === ROUTES.MESSAGES;
    const shouldUseTempVariant = isMessagesPage ? isStreamMdScreen : isMdScreen;

    return (
        <Drawer
            variant={shouldUseTempVariant ? "temporary" : "permanent"}
            open={isOpen}
            data-testid={TEST_IDS.SIDEBAR}
            onClose={() => setIsOpen(false)}
        >
            <SideBarContent isOpen={isOpen} setIsOpen={setIsOpen} />
        </Drawer>
    );
}

SideBar.propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
};

const drawerWidth = 300;
const closedDrawerWidth = 90;

const getOpenBarStyle = (theme, bgColor) => ({
    backgroundColor: bgColor,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    borderRight: 0,
});

const getClosedBarStyle = (theme, bgColor) => ({
    backgroundColor: bgColor,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: closedDrawerWidth,
    borderRight: 0,
});

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...getOpenBarStyle(theme),
        "& .MuiDrawer-paper": getOpenBarStyle(
            theme,
            theme.palette.colors.sidebar.background
        ),
    }),
    ...(!open && {
        ...getClosedBarStyle(theme),
        "& .MuiDrawer-paper": getClosedBarStyle(
            theme,
            theme.palette.colors.sidebar.background
        ),
    }),
}));
