import { makeVar, useReactiveVar } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";

// Exported for testing only.
export const tokenVar = makeVar();

export function useIsAuthenticated() {
    const { isAuthenticated } = useAuth();

    return isAuthenticated;
}

export function useHasBeenAuthenticated() {
    const { hasBeenAuthenticated } = useAuth();

    return hasBeenAuthenticated;
}

export function useAuthToken() {
    const { token } = useAuth();

    return token;
}

export function useAuth() {
    const { isAuthenticated: isAuthenticatedAuth0, getAccessTokenSilently } =
        useAuth0();
    const token = useReactiveVar(tokenVar);
    const [error, setError] = useState();

    useEffect(() => {
        if (isAuthenticatedAuth0) {
            getAndSetAccessToken({
                getAccessTokenSilently,
                setError,
            });
        }
    }, [isAuthenticatedAuth0, getAccessTokenSilently]);

    const isAuthenticated = isAuthenticatedAuth0 && !!token;

    return {
        token,
        error,
        isAuthenticated,
        hasBeenAuthenticated: isAuthenticatedAuth0,
    };
}

async function getAndSetAccessToken({ getAccessTokenSilently, setError }) {
    try {
        const accessToken = await getAccessTokenSilently();
        tokenVar(accessToken);
    } catch (e) {
        setError(e);
    }
}
