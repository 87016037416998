export function hasMinimumAccountInformation(user) {
    return !!user?.first_name;
}

export function hasAtLeastOneCompany(user) {
    return user?.companies?.length > 0;
}

export function hasSubscriptionForCompany({ user, companyId }) {
    const company = user?.companies?.find(({ id }) => id === companyId);

    return !!company?.subscription_id;
}

export function hasCompletedSignup({ user, companyId }) {
    return hasSubscriptionForCompany({ user, companyId });
}
