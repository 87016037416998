let redirectAppState;

export function setRedirectAppState(to) {
    redirectAppState = to;
}

// Default searchString to current search params. This prevents losing them when
// logging in without visiting a protected route first (which sets redirectAppState).
export function getRedirectAppState() {
    const searchString =
        redirectAppState?.searchString || window.location.search;

    return { ...redirectAppState, searchString };
}
