import { useSnackBar } from "@boomnation/lib-web-common";
import { useEffect, useState } from "react";

import { useAuthToken } from "~app/hooks/authHooks";
import {
    fetchAttachmentSafely,
    uploadAttachment as uploadAttachmentCdn,
    fetchAttachmentsZipSafely,
    uploadAttachmentSafely as uploadAttachmentSafelyCdn,
} from "~services/cdn";
import { downloadUrl } from "~services/downloadHelpers";

export function useAttachmentImage({ attachmentId, url, variant, onLoad }) {
    const [remoteUrl, setRemoteUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [didFailLoadingImage, setDidFailLoadingImage] = useState(false);
    const [didFailLoadingVariant, setDidFailLoadingVariant] = useState(false);
    const token = useAuthToken();

    // Try a variant if:
    // - we're passed a variant if
    // - and the variant has not failed loading
    // - and we haven't already downloaded any asset (variant or not)
    const shouldTryVariant = variant && !didFailLoadingVariant && !remoteUrl;

    const variantId = `${attachmentId}_${variant}`;

    const fn = async () => {
        // Don't do anything if:
        // - there's no attachment id passed
        // - or if we're already loading something
        // - or if we already have a remote url
        // - or if we failed to load image
        if (!attachmentId || isLoading || remoteUrl || didFailLoadingImage) {
            return;
        }

        setIsLoading(true);
        const imgUrl = await fetchAttachmentSafely({
            id: shouldTryVariant ? variantId : attachmentId,
            url,
            token,
        });

        if (!imgUrl && shouldTryVariant) {
            setDidFailLoadingVariant(true);
            setIsLoading(false);

            return;
        }

        if (imgUrl) {
            setDidFailLoadingVariant(false);
            setRemoteUrl(imgUrl);
            onLoad?.(imgUrl);
        } else {
            setDidFailLoadingImage(true);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (!attachmentId && !url) {
            setRemoteUrl(null);
        }
        if (!token) return;

        fn();
    }, [token, attachmentId, url, shouldTryVariant, isLoading, remoteUrl]);

    return { remoteUrl, isLoading, didFailLoadingImage, refetch: fn };
}

export function useUploadAttachment() {
    const token = useAuthToken();

    const uploadAttachment = async ({ attachment, permissions }) => {
        const attachmentInfo = await uploadAttachmentCdn({
            attachment,
            token,
            permissions,
        });

        return attachmentInfo;
    };

    return uploadAttachment;
}

export function useDownloadAttachmentsZip({ attachmentIdList }) {
    const token = useAuthToken();
    const { showError } = useSnackBar();

    return async () => {
        if (!attachmentIdList?.length) return;

        const attachmentsZipUrl = await fetchAttachmentsZipSafely({
            attachmentIdList,
            token,
            onError: (error) => showError({ error }),
        });

        downloadUrl({
            href: attachmentsZipUrl,
        });
    };
}

export function useUploadAttachmentSafely() {
    const token = useAuthToken();
    const { showError } = useSnackBar();

    const uploadAttachmentSafely = async ({ attachment, permissions }) => {
        const attachmentInfo = await uploadAttachmentSafelyCdn({
            attachment,
            token,
            permissions,
            showError,
        });

        return attachmentInfo;
    };

    return uploadAttachmentSafely;
}
