import { gql } from "@apollo/client";

import {
    CURRENT_USER_PROPERTIES,
    COMPANY_USER_PROPERTIES,
    USER,
} from "~graphql/schemas/users";

export const GET_CURRENT_USER = gql`
    query User {
        getCurrentUser {
            ${CURRENT_USER_PROPERTIES}
        }
    }
`;

export const SEARCH_USERS = gql`
    query Query(
        $searchKeyword: String
        $limit: PositiveInt
        $offset: NonNegativeInt
        $sort: SortInput
    ) {
        getUsers(
            searchKeyword: $searchKeyword
            limit: $limit
            offset: $offset
            sort: $sort
        ) {
            results {
                id
                first_name
                last_name
                photo_url
            }
            meta {
                total
            }
        }
    }
`;

export const GET_COMPANY_CHAT_TOKEN = gql`
    query GetCompanyChatToken($companyId: UUID!) {
        getCompanyChatToken(companyId: $companyId) {
            token
        }
    }
`;

// eslint-disable-next-line no-secrets/no-secrets
export const GET_COMPANY_USER_SUPPORT_CHAT_TOKEN = gql`
    query Query {
        getCompanyUserSupportChatToken {
            token
        }
    }
`;

export const GET_USER_TRADES = gql`
    query GetUserTrades($getUserId: String!) {
        getUser(id: $getUserId) {
            user_trades {
                id
                is_primary
                trade {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_COMPANY_USERS = gql`
    query Query(
        $companyId: UUID!
        $limit: PositiveInt
        $offset: NonNegativeInt
        $sort: SortInput
        $searchKeyword: String
    ) {
        getCompanyUsers(
            companyId: $companyId
            limit: $limit
            offset: $offset
            sort: $sort
            searchKeyword: $searchKeyword
        ) {
            results {
                ${COMPANY_USER_PROPERTIES}
            }
            meta {
                total
            }
        }
    }
`;

export const GET_COMPANY_ENTITY_USERS = gql`
    query Query(
        $companyId: UUID!
        $entityId: UUID
        $limit: PositiveInt
        $offset: NonNegativeInt
        $sort: SortInput
        $searchKeyword: String
    ) {
        getCompanyEntityUsers(
            companyId: $companyId
            entityId: $entityId
            limit: $limit
            offset: $offset
            sort: $sort
            searchKeyword: $searchKeyword
        ) {
            results {
                ${COMPANY_USER_PROPERTIES}
            }
            meta {
                total
            }
        }
    }
`;

export const GET_COMPANY_ENTITY_USERS_CSV = gql`
    query Query($companyId: UUID!, $entityId: UUID!, $userIdList: [String!]) {
        getCompanyEntityUsersCsv(
            companyId: $companyId
            entityId: $entityId
            userIdList: $userIdList
        )
    }
`;

export const GET_COMPANY_USERS_CSV = gql`
    query Query($companyId: UUID!, $userIdList: [String!]) {
        getCompanyUsersCsv(companyId: $companyId, userIdList: $userIdList)
    }
`;

export const GET_USER = gql`
    query GetUser($getUserId: String!) {
        getUser(id: $getUserId) {
            ${USER}
        }
    }
`;
