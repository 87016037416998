/* eslint max-lines: ["error", {"max": 300}] */
import ApartmentIcon from "@mui/icons-material/Apartment";
import BusinessIcon from "@mui/icons-material/Business";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DescriptionIcon from "@mui/icons-material/Description";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ForumRoundedIcon from "@mui/icons-material/ForumRounded";
import HandymanIcon from "@mui/icons-material/Handyman";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";

import {
    ROUTES,
    PAGES,
    FEATURE_FLAGS,
    FEATURE_GROUPS,
    COMPANY_FEATURES,
} from "~app/constants";
import { COPY } from "~app/copyConstants";
import { useUnreadMessagesCount } from "~app/hooks/chatHooks";
import { getCurrentCompanyRoute } from "~app/navigationHelpers";
import { useFeatureFlags } from "~app/Providers/FeatureFlagProvider";
import { useCurrentCompany } from "~graphql/hooks/currentCompany";
import { useIsInternalUser } from "~graphql/hooks/users";

function getSidebarItems({ companyId, unreadMessagesCount }) {
    return [
        {
            label: COPY.DASHBOARD.EN,
            icon: DashboardIcon,
            route: ROUTES.DASHBOARD,
            name: PAGES.DASHBOARD,
        },
        {
            label: "Jobs",
            icon: BusinessCenterIcon,
            route: ROUTES.JOBS,
            name: PAGES.JOBS,
            requiredFeatureGroup: FEATURE_GROUPS.HIRING,
        },
        {
            label: "Candidates",
            icon: EngineeringIcon,
            route: ROUTES.CANDIDATES,
            name: PAGES.CANDIDATES,
            requiredFeatureGroup: FEATURE_GROUPS.HIRING,
        },
        {
            label: "Private Partners",
            icon: ApartmentIcon,
            route: ROUTES.PARTNERS,
            name: PAGES.PARTNERS,
            isOnlyInternal: true,
        },
        {
            label: COPY.CAMPAIGNS.EN,
            icon: NotificationsActiveIcon,
            route: ROUTES.CAMPAIGNS,
            name: PAGES.CAMPAIGNS,
        },
        {
            label: "Company",
            icon: BusinessIcon,
            route: getCurrentCompanyRoute({ companyId }),
            name: PAGES.PARTNER,
            isOnlyCompany: true,
            featureFlagKey: FEATURE_FLAGS.SHOW_COMPANY_PAGE,
            requiredFeatureGroup: FEATURE_GROUPS.HIRING,
        },
        {
            label: "People",
            icon: PeopleIcon,
            route: ROUTES.PEOPLE,
            name: PAGES.PEOPLE,
            isOnlyCompany: true,
        },
        {
            label: COPY.FORMS.EN,
            icon: DescriptionIcon,
            route: ROUTES.FORMS,
            name: PAGES.FORMS,
            isOnlyCompany: true,
            companyFeatureKeysAccepted: [
                COMPANY_FEATURES.COMPANY_CARDS,
                COMPANY_FEATURES.PROJECT_CARDS,
            ],
        },
        {
            label: "Projects",
            icon: HandymanIcon,
            route: ROUTES.PROJECTS,
            name: PAGES.PROJECTS,
            isOnlyCompany: true,
            requiredFeatureGroup: FEATURE_GROUPS.PROJECTS,
        },
        {
            label: COPY.TEMPLATES.EN,
            icon: ListAltOutlinedIcon,
            route: ROUTES.TEMPLATES,
            name: PAGES.TEMPLATES,
            isOnlyCompany: true,
            companyFeatureKeysAccepted: [
                COMPANY_FEATURES.COMPANY_CARDS,
                COMPANY_FEATURES.PROJECT_CARDS,
                COMPANY_FEATURES.COMPANY_BULLETIN,
                COMPANY_FEATURES.PROJECT_BULLETINS,
            ],
        },
        {
            label: COPY.MESSAGES.EN,
            icon: ForumRoundedIcon,
            route: ROUTES.MESSAGES,
            name: PAGES.MESSAGES,
            isOnlyCompany: true,
            badgeContent: unreadMessagesCount,
            badgeColor: "error",
        },
        {
            label: COPY.ACCOUNT.EN,
            icon: SettingsIcon,
            route: ROUTES.SETTINGS,
            name: PAGES.SETTINGS,
        },
    ];
}

export function useSideBarItems() {
    const { featureFlags, isLoading: isFeatureFlagsLoading } =
        useFeatureFlags();
    const {
        companyId,
        companyFeatureGroups,
        companyFeatures,
        isLoading: isCurrentCompanyLoading,
    } = useCurrentCompany();
    const { isInternalUser, isLoading: isUserLoading } = useIsInternalUser();
    const unreadMessagesCount = useUnreadMessagesCount();

    const sideBarItems = getViewbleSidebarItems({
        isInternalUser,
        featureFlags,
        companyId,
        companyFeatureGroups,
        unreadMessagesCount,
        companyFeatures,
    });
    const isLoading =
        isFeatureFlagsLoading || isCurrentCompanyLoading || isUserLoading;

    return { sideBarItems, isLoading };
}

function getViewbleSidebarItems({
    isInternalUser,
    featureFlags,
    companyFeatureGroups,
    companyId,
    unreadMessagesCount,
    companyFeatures,
}) {
    return isInternalUser
        ? filterViewableItemsForInternalUser({
              featureFlags,
              companyFeatureGroups,
              companyId,
          })
        : filterViewableItemsForNonInternalUser({
              featureFlags,
              companyFeatureGroups,
              companyId,
              unreadMessagesCount,
              companyFeatures,
          });
}

function filterViewableItemsForInternalUser({
    featureFlags,
    companyFeatureGroups,
    companyId,
}) {
    return filterSideBarItems({
        shouldIncludeProp: "isOnlyCompany",
        isInternalUser: true,
        featureFlags,
        companyFeatureGroups,
        companyId,
    });
}

function filterViewableItemsForNonInternalUser({
    featureFlags,
    companyFeatureGroups,
    companyId,
    unreadMessagesCount,
    companyFeatures,
}) {
    return filterSideBarItems({
        shouldIncludeProp: "isOnlyInternal",
        featureFlags,
        companyFeatureGroups,
        companyId,
        unreadMessagesCount,
        companyFeatures,
    });
}

function filterSideBarItems({
    isInternalUser = false,
    featureFlags,
    companyFeatureGroups,
    shouldIncludeProp,
    companyId,
    unreadMessagesCount,
    companyFeatures,
}) {
    const sidebarItems = getSidebarItems({ companyId, unreadMessagesCount });

    return sidebarItems.filter((item) =>
        isViewable({
            isInternalUser,
            shouldInclude: !item[shouldIncludeProp],
            item,
            featureFlags,
            companyFeatureGroups,
            companyFeatures,
        })
    );
}

function isViewable({
    isInternalUser,
    shouldInclude,
    item,
    featureFlags,
    companyFeatureGroups,
    companyFeatures,
}) {
    if (!shouldInclude) return false;

    return (
        isViewableBasedOnCompanyFeatures({ item, companyFeatures }) &&
        isViewableBasedOnFeatureFlag({ item, featureFlags }) &&
        isViewableBasedOnCompanyFeatureGroups({
            item,
            isInternalUser,
            companyFeatureGroups,
        })
    );
}
function isViewableBasedOnFeatureFlag({ item, featureFlags }) {
    // if featureFlags fails, only show items without a feature flag config
    if (!featureFlags) return !item.featureFlagKey;

    // if featureFlagKey is true or undefined show item else do not
    return featureFlags[item.featureFlagKey] !== false;
}

function isViewableBasedOnCompanyFeatures({ item, companyFeatures }) {
    // if companyFeatureKeysAccepted configs are not passed, show side bar item
    if (!item.companyFeatureKeysAccepted) return true;

    // if companyFeatures includes any of the keys passed, show side bar item
    return item.companyFeatureKeysAccepted.some((feature) =>
        companyFeatures?.includes(feature)
    );
}

function isViewableBasedOnCompanyFeatureGroups({
    item,
    isInternalUser,
    companyFeatureGroups = [],
}) {
    // dont hide items based on features from internal user
    if (isInternalUser) return true;

    if (!item.requiredFeatureGroup) return true;

    return companyFeatureGroups.includes(item.requiredFeatureGroup);
}
