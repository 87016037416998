/* eslint max-lines: ["error", {"max": 360}] */
import { useIsAuthenticated } from "~app/hooks/authHooks";
import { updateUserCache } from "~graphql/cache/users";
import { getSortWithKeywordSuffix } from "~graphql/helpers";
import { useQuery, useMutation, useLazyQuery } from "~graphql/hooks/shared";
import { UPDATE_USER } from "~graphql/mutations/users";
import {
    GET_CURRENT_USER,
    SEARCH_USERS,
    GET_COMPANY_CHAT_TOKEN,
    GET_COMPANY_USER_SUPPORT_CHAT_TOKEN,
    GET_USER_TRADES,
    GET_COMPANY_USERS,
    GET_COMPANY_ENTITY_USERS,
    GET_COMPANY_USERS_CSV,
    GET_COMPANY_ENTITY_USERS_CSV,
    GET_USER,
} from "~graphql/queries/users";
import analytics from "~services/analytics";
import { findPrimaryTrade } from "~services/userHelpers";

import { DEBOUNCE_KEYS, DEFAULT_LIMITS, DEFAULT_SORT } from "~constants";

export function useUser() {
    const isAuthenticated = useIsAuthenticated();

    const { error, loading, data, refetch } = useQuery(GET_CURRENT_USER, {
        skip: !isAuthenticated,
    });
    const user = data?.getCurrentUser;

    return {
        error,
        isLoading: loading,
        user,
        refetch,
    };
}

export function useSearchUsers({ searchKeyword, limit, offset, sort }) {
    const { error, loading, data, refetch } = useQuery(SEARCH_USERS, {
        variables: { searchKeyword, limit, offset, sort },
        context: {
            debounceKey: DEBOUNCE_KEYS.SEARCH_USERS,
        },
    });

    const users = data?.getUsers?.results;
    const total = data?.getUsers?.meta?.total;

    return {
        error,
        isLoading: loading,
        users,
        total,
        refetch,
    };
}

export function useCompanyUsers({
    companyId,
    limit = DEFAULT_LIMITS.COMPANY_USERS,
    offset = 0,
    sort = DEFAULT_SORT,
    searchKeyword = null,
}) {
    const KEYWORD_COLUMNS = ["first_name"];

    const sortForSearch = getSortWithKeywordSuffix({
        keywordColumns: KEYWORD_COLUMNS,
        hasSearchKeyword: !!searchKeyword,
        sort,
    });

    const { error, loading, data, refetch } = useQuery(GET_COMPANY_USERS, {
        variables: {
            companyId,
            limit,
            offset,
            sort: sortForSearch,
            searchKeyword,
        },
        skip: !companyId,
        notifyOnNetworkStatusChange: true,
    });

    const companyUsers = data?.getCompanyUsers?.results;
    const total = data?.getCompanyUsers?.meta?.total;

    return {
        error,
        isLoading: loading,
        companyUsers,
        total,
        refetch,
    };
}

export function useCompanyEntityUsers({
    companyId,
    entityId,
    limit = DEFAULT_LIMITS.COMPANY_USERS,
    offset = 0,
    sort = DEFAULT_SORT,
    searchKeyword = null,
}) {
    const KEYWORD_COLUMNS = ["first_name"];

    const sortForSearch = getSortWithKeywordSuffix({
        keywordColumns: KEYWORD_COLUMNS,
        hasSearchKeyword: !!searchKeyword,
        sort,
    });

    const { error, loading, data, refetch } = useQuery(
        GET_COMPANY_ENTITY_USERS,
        {
            variables: {
                companyId,
                entityId,
                limit,
                offset,
                sort: sortForSearch,
                searchKeyword,
            },
            skip: !companyId,
            notifyOnNetworkStatusChange: true,
        }
    );

    const companyUsers = data?.getCompanyEntityUsers?.results;
    const total = data?.getCompanyEntityUsers?.meta?.total;

    return {
        error,
        isLoading: loading,
        companyUsers,
        total,
        refetch,
    };
}

export function useGetCompanyEntityUsersCsv() {
    const [getCsvQuery, { error, loading: isLoading }] = useLazyQuery(
        GET_COMPANY_ENTITY_USERS_CSV,
        { boomnation: { showSnackbarOnError: true } }
    );

    const getCompanyEntityUsersCsv = async ({
        companyId,
        entityId,
        userIdList,
    }) => {
        const { data } = await getCsvQuery({
            variables: { companyId, entityId, userIdList },
            onCompleted: () =>
                analytics.sendDownloadedUsersCsvEvent({ companyId, entityId }),
        });

        return data?.getCompanyEntityUsersCsv;
    };

    return { getCompanyEntityUsersCsv, isLoading, error };
}

export function useGetCompanyUsersCsv() {
    const [downloadCsvQuery, { error, loading: isLoading }] = useLazyQuery(
        GET_COMPANY_USERS_CSV,
        { boomnation: { showSnackbarOnError: true } }
    );

    const getCompanyUsersCsv = async ({ companyId, userIdList }) => {
        const { data } = await downloadCsvQuery({
            variables: { companyId, userIdList },
            onCompleted: () =>
                analytics.sendDownloadedUsersCsvEvent({ companyId }),
        });

        return data?.getCompanyUsersCsv;
    };

    return { getCompanyUsersCsv, isLoading, error };
}

export function useUpdateUser({ onCompleted }) {
    const { user } = useUser();

    const [updateUserMutation, { error, loading }] = useMutation(UPDATE_USER, {
        onCompleted,
    });

    const updateUser = (userUpdate) => {
        updateUserMutation({
            variables: {
                id: user?.id,
                update: userUpdate,
            },
            update(cache, { data }) {
                updateUserCache({ cache, update: data.updateUser });
            },
        });
    };

    return { updateUser, error, isLoading: loading };
}

export function useIsInternalUser() {
    const { user, error, isLoading, refetch } = useUser();
    const isInternalUser = user?.is_internal;

    return { isInternalUser, error, isLoading, refetch };
}

export function useCompanyChatToken({ companyId }) {
    const { error, loading, data } = useQuery(GET_COMPANY_CHAT_TOKEN, {
        variables: { companyId },
        skip: !companyId,
    });

    const chatToken = data?.getCompanyChatToken?.token;

    return { error, isLoading: loading, chatToken };
}

export function useGetUserTrades(userId) {
    const { error, loading, data } = useQuery(GET_USER_TRADES, {
        variables: {
            getUserId: userId,
        },
    });

    const userTrades = data?.getUser?.user_trades;
    const primaryTrade = findPrimaryTrade(userTrades)?.trade?.name;

    return { error, isLoading: loading, userTrades, primaryTrade };
}

export function useCompanyUserSupportChatToken({ shouldSkip }) {
    const { error, loading, data } = useQuery(
        GET_COMPANY_USER_SUPPORT_CHAT_TOKEN,
        { skip: shouldSkip }
    );

    const supportChatToken = data?.getCompanyUserSupportChatToken?.token;

    return { error, isLoading: loading, supportChatToken };
}

export const useGetUser = (id) => {
    const isAuthenticated = useIsAuthenticated();

    const { error, loading, data, refetch } = useQuery(GET_USER, {
        variables: {
            getUserId: id,
        },
        skip: !isAuthenticated || !id,
    });

    const user = data?.getUser;

    return { error, isLoading: loading, user, refetch };
};
