import { Box, Button, styled, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import BackgroundImage from "~images/mobile-signup-background.png";
import ErrorText from "~shared/Error/ErrorText";
import Logo, { VARIANTS } from "~shared/Logo/Logo";

export default function PostSignupCompanyOwnerMobile({
    companyName,
    setCompanyName,
    onSubmit,
    isLoading,
    error,
}) {
    return (
        <>
            <Background />
            <Container>
                <StyledLogo variant={VARIANTS.BN_LOGO_WHITE} noMargin />
                <InputContainer>
                    <Title>Enter your company name to get started</Title>
                    <input
                        style={{
                            width: "100%",
                            marginBottom: "20px",
                            height: "2.7rem",
                            paddingLeft: "1rem",
                            borderWidth: "1px",
                            marginTop: "20px",
                            outline: "none",
                            backgroundColor: "transparent",
                            border: "1.5px solid white",
                            borderRadius: "5px",
                            color: "white",
                        }}
                        placeholder="Company Name"
                        onChange={(e) => setCompanyName(e.target.value)}
                        value={companyName}
                    />
                </InputContainer>
                <SubmitButtonContainer>
                    <StyledButton onClick={onSubmit} disabled={isLoading}>
                        Submit
                    </StyledButton>
                    <ErrorText error={error} />
                </SubmitButtonContainer>
            </Container>
        </>
    );
}

PostSignupCompanyOwnerMobile.propTypes = {
    companyName: PropTypes.string,
    setCompanyName: PropTypes.func,
    onSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    error: PropTypes.any,
};

const Background = styled(Box)(() => ({
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
    width: "100vw",
    position: "absolute",
    zIndex: 1,
}));

const Container = styled(Box)(() => ({
    display: "flex",
    zIndex: 2,
    position: "relative",
    paddingTop: "6rem",
    paddingBottom: "6rem",
    height: "100vh",
    flexDirection: "column",
    width: "100vw",
    justifyContent: "space-between",
    alignItems: "center",
}));

const StyledLogo = styled(Logo)(() => ({
    height: "1.5rem",
    width: "12rem",
}));

const SubmitButtonContainer = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
}));

const InputContainer = styled(Box)(() => ({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: "1rem",
    paddingRight: "1rem",
}));

const Title = styled(Typography)(() => ({
    width: "50%",
    textAlign: "center",
    color: "white",
    fontWeight: "500",
}));

const StyledButton = styled(Button)(() => ({
    color: "white",
    textTransform: "none",
    fontSize: "1rem",
}));
