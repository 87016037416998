import PropTypes from "prop-types";
import React, { useState, useContext } from "react";

export const NavBarContext = React.createContext();

export const useNavBarState = () => useContext(NavBarContext);

export default function NavBarProvider({ children }) {
    const [isSideBarOpen, setSideBarOpen] = useState(false);

    return (
        <NavBarContext.Provider value={{ isSideBarOpen, setSideBarOpen }}>
            {children}
        </NavBarContext.Provider>
    );
}

NavBarProvider.propTypes = {
    children: PropTypes.any,
};
