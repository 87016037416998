import PropTypes from "prop-types";
import React from "react";

import SideBarHeader from "~shared/SideBar/SideBarHeader";
import SideBarListItems from "~shared/SideBar/SideBarListItems";

export default function SideBarContent({ isOpen, setIsOpen }) {
    const handleDrawerOpenClose = () => {
        setIsOpen((previousState) => !previousState);
    };

    return (
        <>
            <SideBarHeader
                onIconClick={handleDrawerOpenClose}
                isOpen={isOpen}
            />
            <SideBarListItems isOpen={isOpen} />
        </>
    );
}

SideBarContent.propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
};
