import { gql } from "@apollo/client";

import { COMPANY_SCHEMA, COMPANY_INVITATION } from "~graphql/schemas/companies";

export const GET_COMPANY = gql`
    query getCompany($id: UUID!) {
        getCompany(id: $id) {
            ${COMPANY_SCHEMA}
        }
    }
`;

export const GET_COMPANY_SUBSCRIPTION_INFO = gql`
    query getCompany($id: UUID!) {
        getCompany(id: $id) {
            subscription_id
            expiration_expires_at
            is_pay_as_you_go
        }
    }
`;

export const GET_COMPANIES = gql`
    query getCompanies(
        $searchKeyword: String
        $limit: PositiveInt
        $offset: NonNegativeInt
    ) {
        getCompanies(
            searchKeyword: $searchKeyword
            limit: $limit
            offset: $offset
        ) {
            results {
                id
                name
                bio
                address {
                    city
                    state
                }
                expiration_expires_at
                hubspot_id
            }
            meta {
                total
            }
        }
    }
`;

export const GET_COMPANY_INVITATIONS = gql`
    query getCompanyInvitations($code: String) {
        getCompanyInvitations(code: $code) {
            ${COMPANY_INVITATION}
            company_name
            inviter_first_name
            inviter_last_name
        }
    }
`;

export const GET_PENDING_COMPANY_INVITATIONS = gql`
    query Query(
        $companyId: UUID!
        $entityId: UUID
        $limit: PositiveInt
        $offset: NonNegativeInt
        $sort: SortInput
        $searchKeyword: String
    ) {
        getPendingCompanyInvitations(
            companyId: $companyId
            entityId: $entityId
            limit: $limit
            offset: $offset
            sort: $sort
            searchKeyword: $searchKeyword
        ) {
            results {
                ${COMPANY_INVITATION}
            }
            meta {
                total
            }
        }
    }
`;
