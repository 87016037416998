import PropTypes from "prop-types";
import React from "react";

import { useAttachmentImage } from "~app/hooks/cdnHooks";
import BasicAvatar from "~shared/Avatar/BasicAvatar";

import { IMAGE_VARIANTS } from "~constants";

export default function Avatar({ size, attachmentId, alt, ...props }) {
    const { remoteUrl } = useAttachmentImage({
        attachmentId,
        variant: IMAGE_VARIANTS.THUMBNAIL,
    });

    return (
        <BasicAvatar
            {...props}
            w={size}
            h={size}
            imageUrl={remoteUrl}
            alt={alt}
        />
    );
}

Avatar.propTypes = {
    size: PropTypes.number,
    attachmentId: PropTypes.string,
    alt: PropTypes.string,
};
