/* eslint max-lines: ["error", {"max": 350}] */
import { omit, moveItemInArray } from "@boomnation/lib-common";

export function readQuery({ cache, query, variables, pathToData }) {
    const queryData = cache.readQuery({ query, variables });

    return queryData?.[pathToData];
}

export function writeQuery({
    cache,
    query,
    variables,
    pathToData,
    subPathToData,
    update,
}) {
    const updatePathOrSubpath = subPathToData
        ? { [subPathToData]: update }
        : update;

    return cache.writeQuery({
        query,
        variables,
        data: {
            [pathToData]: updatePathOrSubpath,
        },
    });
}

export function removeEntitiesFromPaginatedQuery({
    cache,
    query,
    variables,
    pathToData,
    entityIdList,
}) {
    const queryData = readQuery({ cache, query, variables, pathToData });

    if (!queryData) return;

    const { results, meta } = queryData;
    const updatedResults = results.filter(
        (result) => !entityIdList.includes(result.id)
    );
    const updatedMeta = { total: Math.max(meta.total - 1, 0) };
    const updatedQueryData = {
        ...queryData, // Spread to keep query typename
        results: updatedResults,
        meta: updatedMeta,
    };
    const updatedData = { [pathToData]: updatedQueryData };

    cache.writeQuery({ query, variables, data: updatedData });
}

export function removeEntityFromListQuery({
    cache,
    query,
    variables,
    pathToData,
    entityId,
}) {
    const queryData = readQuery({ cache, query, variables, pathToData });

    if (!queryData) return;

    const updatedResults = queryData.filter((result) => result.id !== entityId);
    const updatedData = { [pathToData]: updatedResults };

    cache.writeQuery({ query, variables, data: updatedData });
}

// Passing a typeName allows overriding the __typename on the entityOrEntities.
// If we pass data directly from a mutation, the typename may be something like
// ProjectMutationResponse instead of Project.
export function addEntityOrEntitiesToPaginatedQuery({
    cache,
    query,
    variables,
    pathToData,
    entityOrEntities,
    shouldAddToBeginning,
    typeName,
    shouldSkipIfCacheNotFound,
}) {
    const queryData = readQuery({ cache, query, variables, pathToData });

    // We can use this to prevent writing to the cache of a query that hasn't been fired yet.
    // Doing so, would cause the first actual request to read from the cache instead of network.
    if (!queryData && shouldSkipIfCacheNotFound) return;

    const total = queryData?.meta?.total || 0;

    const { entities, updatedResults } = formatEntitiesAndAddData({
        entityOrEntities,
        typeName,
        results: queryData?.results,
        shouldAddToBeginning,
    });

    const existingMeta = queryData?.meta || {};

    const updatedMeta = { ...existingMeta, total: total + entities.length };
    const updatedQueryData = {
        ...queryData, // Spread to keep query typename
        results: updatedResults,
        meta: updatedMeta,
    };
    const updatedData = { [pathToData]: updatedQueryData };

    cache.writeQuery({ query, variables, data: updatedData });
}

export function addEntityOrEntitiesToListQuery({
    cache,
    query,
    variables,
    pathToData,
    entityOrEntities,
    shouldAddToBeginning,
    typeName,
}) {
    const queryData = readQuery({ cache, query, variables, pathToData });

    const { updatedResults } = formatEntitiesAndAddData({
        entityOrEntities,
        typeName,
        results: queryData,
        shouldAddToBeginning,
    });

    const updatedData = { [pathToData]: updatedResults };

    cache.writeQuery({ query, variables, data: updatedData });
}

export function updateEntityInCache({
    cache,
    typeName,
    fragment,
    entityId,
    update,
}) {
    cache.writeFragment({
        id: `${typeName}:${entityId}`,
        fragment,
        // Don't overwrite typename with mutation response.
        // The typename on mutation responses may be something like ProjectMutationResponse,
        // instead of Project. So, by omitting __typename, we can keep the original from the query.
        data: omit(update, ["__typename"]),
    });
}

export function updateEntityPositionInCache({
    cache,
    query,
    variables,
    pathToData,
    entityId,
    position,
}) {
    const queryData = readQuery({ cache, query, variables, pathToData });

    const entity = queryData.find((data) => data.id === entityId);

    const updatedQueryData = moveItemInArray({
        array: queryData,
        item: entity,
        position,
    });

    const updatedData = { [pathToData]: updatedQueryData };

    cache.writeQuery({ query, variables, data: updatedData });
}

// As with updates, create mutation responses can have typenames like ProjectMutationResponse,
// instead of Project. In these cases, we can manually pass the correct typename here.
// This prevents the situation of the new entity having a cache key "ProjectMutationResponse:project-id".
// Which would make predecting the cache id for writeFragment difficult.
function formatEntitiesBeforeAddingToCache({ entityOrEntities, typeName }) {
    const entities = Array.isArray(entityOrEntities)
        ? entityOrEntities
        : [entityOrEntities];

    // Just return if we're not overriding the __typename.
    if (!typeName) return entities;

    return entities.map((entity) => ({ ...entity, __typename: typeName }));
}

function formatEntitiesAndAddData({
    entityOrEntities,
    typeName,
    results = [],
    shouldAddToBeginning,
}) {
    const entities = formatEntitiesBeforeAddingToCache({
        entityOrEntities,
        typeName,
    });

    const updatedResults = shouldAddToBeginning
        ? [...entities, ...results]
        : [...results, ...entities];

    return { updatedResults, entities };
}

export function addOrReplaceEntityInArray({ entityArray, entity }) {
    const foundEntity = findEntityInArray({ entityArray, id: entity.id });

    return foundEntity
        ? replaceEntityInArray({ entityArray, replacementEntity: entity })
        : addEntityOrEntitiesToArray({
              entityArray,
              newEntityOrEntities: entity,
          });
}

export function replaceEntityInArray({ entityArray, replacementEntity }) {
    return entityArray.map((entity) =>
        entity.id === replacementEntity.id ? replacementEntity : entity
    );
}

export function addEntityOrEntitiesToArray({
    entityArray,
    newEntityOrEntities,
}) {
    return Array.isArray(newEntityOrEntities)
        ? [...entityArray, ...newEntityOrEntities]
        : [...entityArray, newEntityOrEntities];
}

export function findEntityInArray({ entityArray, id }) {
    return entityArray.find((entity) => entity.id === id);
}

export function removeEntityFromArray({
    entityArray,
    id,
    entityIdProperty = "id",
}) {
    return entityArray.filter((entity) => entity[entityIdProperty] !== id);
}

export function addOrRemoveEntityFromMeta({
    updatedEntity,
    cache,
    variables,
    query,
    pathToData,
    entitiesToUpdateKey,
    shouldAddToMeta,
}) {
    const queryData = readQuery({ cache, query, variables, pathToData });

    const { results, meta } = queryData;
    const { id: updatedEntityId } = updatedEntity;

    const updatedMeta = {
        ...meta,
        [entitiesToUpdateKey]: shouldAddToMeta
            ? [updatedEntity, ...meta[entitiesToUpdateKey]]
            : meta[entitiesToUpdateKey].filter(
                  ({ id }) => id !== updatedEntityId
              ),
    };

    const updatedQueryData = {
        ...queryData,
        results,
        meta: updatedMeta,
    };

    const updatedData = { [pathToData]: updatedQueryData };

    cache.writeQuery({
        query,
        variables,
        data: updatedData,
    });
}
