import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import Badge from "@mui/material/Badge";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { ROUTES, PAGES } from "~app/constants";
import { useNavigationHelpers } from "~app/navigationHelpers";
import { useSideBarItems } from "~shared/SideBar/sideBarItems";

const listItemTyporgraphyProps = { variant: "h5" };

export default function SideBarListItems({ isOpen }) {
    const { t } = useTranslation();
    const location = useLocation();
    const { goToPage } = useNavigationHelpers();

    const { sideBarItems, isLoading } = useSideBarItems();

    // TODO create sideBarListItems skeleton
    if (isLoading) return null;

    const isActiveRoute = (item) => {
        const routeName =
            location.pathname === ROUTES.DASHBOARD
                ? PAGES.DASHBOARD
                : location.pathname.substring(1);

        return routeName.includes(item.name);
    };

    return (
        <List>
            {sideBarItems.map((item) => (
                <StyledListItemButton
                    key={item.label}
                    onClick={() => goToPage({ route: item.route })}
                    isHighlighted={isActiveRoute(item)}
                >
                    <StyledListItemIcon isHighlighted={isActiveRoute(item)}>
                        <StyledBadge
                            badgeContent={item.badgeContent}
                            invisible={!item.badgeContent}
                            color={item.badgeColor}
                        >
                            <item.icon />
                        </StyledBadge>
                    </StyledListItemIcon>
                    {isOpen && (
                        <ListItemText
                            primaryTypographyProps={listItemTyporgraphyProps}
                            primary={t(item.label)}
                        />
                    )}
                </StyledListItemButton>
            ))}
        </List>
    );
}

SideBarListItems.propTypes = {
    isOpen: PropTypes.bool,
};

const StyledListItemButton = styled(ListItemButton, {
    shouldForwardProp: isPropValid,
})(({ theme, isHighlighted }) => ({
    color: isHighlighted
        ? theme.palette.primary.main
        : theme.palette.colors.sidebar.label,
    borderLeft: isHighlighted
        ? `5px solid ${theme.palette.primary.main}`
        : null,
}));

const StyledListItemIcon = styled(ListItemIcon, {
    shouldForwardProp: isPropValid,
})(({ theme, isHighlighted }) => ({
    color: isHighlighted
        ? theme.palette.primary.main
        : theme.palette.colors.sidebar.label,
    marginLeft: isHighlighted && -5,
    justifyContent: "center",
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        color: theme.palette.text.light,
    },
}));
