import { useEffect } from "react";

import { useUser } from "~graphql/hooks/users";
import analytics from "~services/analytics";

export function useUserAnalytics() {
    const { user } = useUser();

    useEffect(() => {
        if (!user) return;

        analytics.sendIdentifyEvent(user);
    }, [user?.id]);
}
