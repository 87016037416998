import PropTypes from "prop-types";
import React from "react";

import { useCompany } from "~graphql/hooks/companies";
import Avatar from "~shared/Avatar/Avatar";

// Add space so we get `BN` as initials
const BN_ALT = "Boom Nation";

export default function CompanyAvatar({ size = 20, id, ...props }) {
    const { company } = useCompany(id);

    return (
        <Avatar
            {...props}
            size={size}
            attachmentId={company?.image_url}
            alt={id ? company?.name : BN_ALT}
        />
    );
}

CompanyAvatar.propTypes = {
    size: PropTypes.number,
    id: PropTypes.string,
};
