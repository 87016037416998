import { makeVar, useReactiveVar } from "@apollo/client";
import { useEffect } from "react";

import { FEATURE_GROUPS, USER_COMPANY_ROLES } from "~app/constants";
import {
    useIsAuthenticated,
    useHasBeenAuthenticated,
} from "~app/hooks/authHooks";
import { useUser, useIsInternalUser } from "~graphql/hooks/users";
import { isInPast } from "~services/dateHelpers";
import { usePersistedCompany } from "~services/persistHelpers";

export const currentCompanyVar = makeVar({});

// Pass in an externalCompanyId to override the current company id.
// Especially helpful when dealing with internal admin.
export function useCurrentCompany(externalCompanyId) {
    const [persistedCompany, setPersistedCompany] = usePersistedCompany();

    const { user, isLoading: isUserLoading } = useUser();
    const company = useReactiveVar(currentCompanyVar);
    const isAuthenticated = useIsAuthenticated();
    const hasBeenAuthenticated = useHasBeenAuthenticated();

    // Setting the current company is async, so the user can finish loading and
    // the company is still not set for a split second causing unwanted redirects.
    // Internal admin will never have a company id, so we should only wait for non-internal.
    const isCompanyLoading =
        !!user?.companies?.length && !company.id && !user.is_internal;
    const isAuthLoading = hasBeenAuthenticated && !isAuthenticated;

    useEffect(() => {
        setDefaultCurrentCompany({
            user,
            persistedCompany,
            setPersistedCompany,
        });
    }, [user]);

    return {
        isLoading: isAuthLoading || isUserLoading || isCompanyLoading,
        companyId: externalCompanyId || company.id,
        companyName: company.name,
        companyFeatures: company.features,
        companyFeatureGroups: company.feature_groups,
        isSubscriptionActive: validateSubscriptionExpiration(company),
        isPayAsYouGo: company.is_pay_as_you_go,
        canBillingBeManaged: company.can_billing_be_managed,
        isOwner: company.role === USER_COMPANY_ROLES.OWNER,
        isWorker: company.role === USER_COMPANY_ROLES.WORKER,
        setCurrentCompany,
    };
}

export function useDoesCurrentCompanyHaveFeature(featureName) {
    const { companyFeatures } = useCurrentCompany();
    const { isInternalUser } = useIsInternalUser();

    return isInternalUser || companyFeatures?.includes(featureName);
}

export function useDoesCurrentCompanyHaveFeatureGroup(featureGroup) {
    const { companyFeatureGroups } = useCurrentCompany();

    return companyFeatureGroups?.includes(featureGroup);
}

export function useDoesCurrentCompanyHaveHiringFeatureGroup() {
    return useDoesCurrentCompanyHaveFeatureGroup(FEATURE_GROUPS.HIRING);
}

// Check if the companyId passed is the current user's company
export function useIsCurrentCompany(companyId) {
    const { companyId: currentCompanyId, isLoading } = useCurrentCompany();

    return { isCurrentCompany: currentCompanyId === companyId, isLoading };
}

// Only exporting to use in tests.
export function setCurrentCompany(company) {
    currentCompanyVar(company);
}

function setDefaultCurrentCompany({
    user,
    persistedCompany,
    setPersistedCompany,
}) {
    const currentCompany = findCurrentCompany({ persistedCompany, user });

    // If the user logs out, has no companies, or is an admin, set props to null.
    if (!user?.companies?.length || user.is_internal) {
        setCurrentCompany({ id: null, name: "BoomNation" });
        setPersistedCompany(null);

        return;
    }

    // The above "if" statement checks if the user has no companies, so at this point,
    // user.companies[0] will always be there as a fallback if currentCompany wasn't found.
    const newCurrentCompany = currentCompany || user.companies[0];
    setCurrentCompany(newCurrentCompany);
    setPersistedCompany(newCurrentCompany);
}

export function validateSubscriptionExpiration(company) {
    const expiresAt = company?.expiration_expires_at;

    return !!expiresAt && !isInPast(expiresAt);
}

// Finds it on the user to make sure it's up to date. Returns null if not found.
function findCurrentCompany({ persistedCompany, user }) {
    const currentCompanyId = currentCompanyVar().id || persistedCompany?.id;

    return user?.companies?.find(({ id }) => currentCompanyId === id);
}
