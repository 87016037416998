import { Auth0Provider } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import React from "react";

export default function AuthProvider({ history, children }) {
    return (
        <Auth0Provider
            domain={process.env.AUTH0_DOMAIN}
            clientId={process.env.AUTH0_CLIENT_ID}
            audience={process.env.AUTH0_AUDIENCE}
            redirectUri={window.location.origin}
            onRedirectCallback={(appState) =>
                onRedirectCallback({ history, appState })
            }
        >
            {children}
        </Auth0Provider>
    );
}

AuthProvider.propTypes = {
    children: PropTypes.any,
    history: PropTypes.any,
};

export function onRedirectCallback({ history, appState }) {
    history.replace({
        pathname: appState?.returnTo,
        search: appState?.searchString,
    });
}
