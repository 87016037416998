import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import React from "react";

import Logo, { VARIANTS } from "~shared/Logo/Logo";

import { TEST_IDS } from "~constants";

export default function SideBarHeader({ onIconClick, isOpen }) {
    const logoVariant = isOpen ? VARIANTS.BN_LOGO : VARIANTS.WORKON_BADGE;
    const iconButtonProps = {
        onClick: onIconClick,
        size: "large",
        "data-testid": TEST_IDS.SIDEBAR_BUTTON,
    };

    return (
        <DrawerHeader isOpen={isOpen}>
            {isOpen ? (
                <>
                    <Logo variant={logoVariant} />
                    <StyledIconButton {...iconButtonProps}>
                        <KeyboardDoubleArrowLeftIcon />
                    </StyledIconButton>
                </>
            ) : (
                <>
                    <StyledIconButton {...iconButtonProps}>
                        <KeyboardDoubleArrowRightIcon />
                    </StyledIconButton>
                    <StyledCollapsedLogoIcon variant={logoVariant} />
                </>
            )}
        </DrawerHeader>
    );
}

SideBarHeader.propTypes = {
    onIconClick: PropTypes.func,
    isOpen: PropTypes.bool,
};

const DrawerHeader = styled(Box, {
    shouldForwardProp: isPropValid,
})(({ isOpen }) => ({
    display: "flex",
    alignItems: "center",
    flexDirection: isOpen ? "row" : "column",
    justifyContent: isOpen ? "space-evenly" : "flex-end",
    padding: 5,
    marginTop: 20,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.colors.sidebar.label,
    justifySelf: "center",
}));

const StyledCollapsedLogoIcon = styled(Logo)(() => ({
    width: "25%",
    margin: "0.5rem 0 0 0",
    paddingLeft: 0,
}));
