import { gql } from "@apollo/client";

const USER = `
    id
    first_name
    last_name
    email
    phone_number
    created_at
    updated_at
`;

export const UPDATE_USER = gql`
    mutation Mutation($id: String!, $update: UpdateUserInput!) {
        updateUser(id: $id, update: $update) {
            ${USER}
        }
    }
`;
