export const isDevEnvironment = process.env.ENVIRONMENT !== "production";

export function getQrcodeUrl() {
    return `${process.env.API_HOST}/qrcode`;
}

export function getGraphqlUrl() {
    return `${process.env.API_HOST}/graphql`;
}

export function parseEnvBool(variable) {
    if (!variable) return false;

    return JSON.parse(variable);
}
