/* eslint max-lines: ["error", {"max": 375}] */
import loadable from "@loadable/component";
import React from "react";

import { PAGES, ROUTES, FEATURE_GROUPS } from "~constants";

const loadableOpts = { fallback: <div /> };
const Settings = loadable(
    () => import("~pages/Settings/Settings"),
    loadableOpts
);
const Dashboard = loadable(
    () => import("~pages/Dashboard/Dashboard"),
    loadableOpts
);

// PostSignup
const PostSignup = loadable(
    () => import("~pages/PostSignup/PostSignup"),
    loadableOpts
);

// Jobs
const Jobs = loadable(() => import("~pages/Jobs/Jobs"), loadableOpts);
const JobDetails = loadable(
    () => import("~pages/Jobs/JobDetails/JobDetails"),
    loadableOpts
);
const EditJob = loadable(
    () => import("~pages/Jobs/EditJob/EditJob"),
    loadableOpts
);

const DuplicateJob = loadable(
    () => import("~pages/Jobs/DuplicateJob/DuplicateJob"),
    loadableOpts
);

const NewJob = loadable(
    () => import("~pages/Jobs/NewJob/NewJob"),
    loadableOpts
);

const ConfirmJobPurchase = loadable(
    () => import("~pages/Jobs/ConfirmJobPurchase/ConfirmJobPurchase"),
    loadableOpts
);

const Candidates = loadable(
    () => import("~pages/Candidates/Candidates"),
    loadableOpts
);
const Messages = loadable(
    () => import("~pages/Messages/Messages"),
    loadableOpts
);
const Partners = loadable(
    () => import("~pages/Partners/Partners"),
    loadableOpts
);
const EditPartner = loadable(
    () => import("~pages/Partners/EditPartner"),
    loadableOpts
);
const Landing = loadable(() => import("~pages/Landing/Landing"), loadableOpts);

// Company
const Company = loadable(() => import("~pages/Company/Company"), loadableOpts);

// People
const People = loadable(() => import("~pages/People/People"), loadableOpts);
// Projects
const Projects = loadable(
    () => import("~pages/Projects/Projects"),
    loadableOpts
);
const ProjectDetails = loadable(
    () => import("~pages/Projects/ProjectDetails"),
    loadableOpts
);

// Content Blocks
const NewContentBlock = loadable(
    () => import("~pages/NewContentBlocks"),
    loadableOpts
);

const EditContentBlock = loadable(
    () => import("~pages/EditContentBlocks"),
    loadableOpts
);

// Templates
const Templates = loadable(
    () => import("~pages/Templates/Templates"),
    loadableOpts
);

const Forms = loadable(() => import("~pages/Forms/Forms"), loadableOpts);

const Campaigns = loadable(
    () => import("~pages/Campaigns/Campaigns"),
    loadableOpts
);

const CampaignsCandidates = loadable(
    () => import("~pages/Campaigns/CampaignCandidates"),
    loadableOpts
);

const NewCampaign = loadable(() => import("~pages/Campaigns/NewCampaign"));

export default [
    // SETTINGS
    {
        key: PAGES.SETTINGS,
        path: ROUTES.SETTINGS,
        component: Settings,
        exact: true,
    },
    // Dashboard
    {
        key: PAGES.DASHBOARD,
        path: ROUTES.DASHBOARD,
        component: Dashboard,
        exact: true,
    },
    // PostSignup
    {
        key: PAGES.POST_SIGNUP,
        path: ROUTES.POST_SIGNUP,
        component: PostSignup,
    },
    // Jobs
    {
        key: `${PAGES.JOBS}-new`,
        path: `${ROUTES.NEW_JOB}`,
        component: NewJob,
        exact: true,
    },
    {
        key: `${PAGES.JOBS}-edit`,
        path: `${ROUTES.JOBS}/:id/edit`,
        component: EditJob,
        exact: true,
    },
    {
        key: `${PAGES.JOBS}-duplicate`,
        path: `${ROUTES.JOBS}/:id/duplicate`,
        component: DuplicateJob,
        exact: true,
    },
    {
        key: `${PAGES.JOBS}-confirm-purchase`,
        path: ROUTES.CONFIRM_JOB_PURCHASE,
        component: ConfirmJobPurchase,
        exact: true,
    },
    {
        key: PAGES.JOB_DETAILS,
        path: ROUTES.JOB_DETAILS,
        component: JobDetails,
        exact: true,
        requiredFeatureGroup: FEATURE_GROUPS.HIRING,
    },
    {
        key: PAGES.JOBS,
        path: ROUTES.JOBS,
        component: Jobs,
        exact: true,
        requiredFeatureGroup: FEATURE_GROUPS.HIRING,
    },
    // Partners
    {
        key: PAGES.PARTNER_EDIT,
        path: ROUTES.PARTNER_EDIT,
        component: EditPartner,
        exact: true,
    },
    {
        key: PAGES.PARTNER,
        path: ROUTES.PARTNER,
        component: Company,
        exact: true,
        requiredFeatureGroup: FEATURE_GROUPS.HIRING,
    },
    {
        key: PAGES.PARTNERS,
        path: ROUTES.PARTNERS,
        component: Partners,
        exact: true,
    },
    // Candidates
    {
        key: PAGES.CANDIDATES,
        path: ROUTES.CANDIDATES,
        component: Candidates,
        exact: true,
        requiredFeatureGroup: FEATURE_GROUPS.HIRING,
    },
    // Messages
    {
        key: PAGES.MESSAGES,
        path: ROUTES.MESSAGES,
        component: Messages,
        exact: true,
    },
    // People
    {
        key: PAGES.PEOPLE,
        path: ROUTES.PEOPLE,
        component: People,
        exact: true,
    },
    // Forms
    {
        key: PAGES.FORMS,
        path: ROUTES.FORMS,
        component: Forms,
        exact: true,
    },
    // Projects
    {
        key: PAGES.PROJECTS,
        path: ROUTES.PROJECTS,
        component: Projects,
        exact: true,
        requiredFeatureGroup: FEATURE_GROUPS.PROJECTS,
    },
    {
        key: PAGES.PROJECT_DETAILS,
        path: ROUTES.PROJECT_DETAILS,
        component: ProjectDetails,
        exact: true,
        requiredFeatureGroup: FEATURE_GROUPS.PROJECTS,
    },
    // Content Blocks
    {
        key: PAGES.EDIT_CONTENT_BLOCKS,
        path: ROUTES.EDIT_CONTENT_BLOCKS,
        component: EditContentBlock,
        exact: true,
    },
    {
        key: PAGES.NEW_CONTENT_BLOCKS,
        path: ROUTES.NEW_CONTENT_BLOCKS,
        component: NewContentBlock,
        exact: true,
    },
    // Templates
    {
        key: PAGES.TEMPLATES,
        path: ROUTES.TEMPLATES,
        component: Templates,
        exact: true,
    },
    {
        key: PAGES.CAMPAIGNS,
        path: ROUTES.CAMPAIGNS,
        component: Campaigns,
        exact: true,
    },
    {
        key: PAGES.NEW_CAMPAIGN,
        path: ROUTES.NEW_CAMPAIGN,
        component: NewCampaign,
        exact: true,
    },
    {
        key: PAGES.CAMPAIGNS,
        path: ROUTES.CAMPAIGN_CANDIDATES,
        component: CampaignsCandidates,
        exact: true,
    },
    // Landing
    {
        key: PAGES.LANDING,
        path: ROUTES.LANDING,
        component: Landing,
        exact: true,
        isPublic: true,
    },
    // Default
    {
        key: PAGES.DEFAULT,
        component: Dashboard,
    },
];
