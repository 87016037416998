import { uniqueBy } from "@boomnation/lib-common";

import { DEFAULT_SORT } from "~app/constants";

export function getTypePolicies() {
    return {
        Query: {
            fields: {
                getCompanyJobs: {
                    keyArgs: ["companyId", "searchKeyword", "limit"],
                    merge: getEntitiesWithSearchAndSortMerge,
                    read: getEntitiesWithSearchAndSortRead,
                },
                getProjects: {
                    keyArgs: ["companyId", "searchKeyword"],
                    merge: getEntitiesWithSearchAndSortMerge,
                    read: getEntitiesWithSearchAndSortRead,
                },
                getBulletinPosts: {
                    keyArgs: ["entityType", "entityId"],
                    merge: mergePaginatedQuery,
                    read: readPaginatedQuery,
                },
                getPendingCompanyInvitations: {
                    keyArgs: ["companyId", "entityId", "searchKeyword"],
                    merge: getEntitiesWithSearchAndSortMerge,
                    read: getEntitiesWithSearchAndSortRead,
                },
                getCompanyEntityUsers: {
                    keyArgs: ["companyId", "entityId", "searchKeyword"],
                    merge: getEntitiesWithSearchAndSortMerge,
                    read: getEntitiesWithSearchAndSortRead,
                },
                getCompanies: {
                    keyArgs: ["searchKeyword"],
                    merge: getCompaniesMerge,
                    read: getCompaniesRead,
                },
                getTrades: {
                    merge: getTradesMerge,
                },
                getJobCandidatesByCompany: {
                    keyArgs: ["id"],
                    merge: getJobCandidatesByCompanyMerge,
                },
                getTemplates: {
                    keyArgs: ["companyId", "entityType", "searchKeyword"],
                    merge: getEntitiesWithSearchAndSortMerge,
                    read: getEntitiesWithSearchAndSortRead,
                },
                getAllCampaignCandidateResponses: {
                    keyArgs: ["offset", "userId"],
                    merge: mergePaginatedQuery,
                    read: readPaginatedQuery,
                },
                getAllCampaignCandidates: {
                    keyArgs: ["campaignType", "searchKeyword", "offset"],
                    merge: mergePaginatedQuery,
                    read: readPaginatedQuery,
                },
            },
        },
    };
}

// COMPANIES

export function getCompaniesMerge(existing, incoming, { args }) {
    return paginatedQueryMerge({ existing, incoming, args });
}

export function getCompaniesRead(existing, { args }) {
    return isFirstPageAndNotSearch(args) ? existing : undefined;
}

// TRADES

export function getTradesMerge(existing, incoming) {
    return incoming;
}

// CANDIDATES

export function getJobCandidatesByCompanyMerge(existing, incoming, { args }) {
    return !args.searchKeyword ? incoming : existing;
}

// Common helpers

export function getEntitiesWithSearchAndSortMerge(
    existing,
    incoming,
    { args }
) {
    return paginatedQueryMerge({ existing, incoming, args });
}

export function getEntitiesWithSearchAndSortRead(existing, { args }) {
    return isFirstPageAndNotSearch(args) && isDefaultOrNoSort(args)
        ? existing
        : undefined;
}

function paginatedQueryMerge({ existing, incoming, args }) {
    return isFirstPageAndNotSearch(args) ? incoming : existing;
}

export function isFirstPageAndNotSearch({ offset, searchKeyword }) {
    return offset === 0 && !searchKeyword;
}

export function isDefaultOrNoSort({ sort }) {
    const isDefaultSortBy = sort?.by === DEFAULT_SORT.by;
    const isDefaultSortOrder = sort?.order === DEFAULT_SORT.order;
    const isDefaultSort = isDefaultSortBy && isDefaultSortOrder;

    return !sort || isDefaultSort;
}

export function mergePaginatedQuery(existing, incoming, { args }) {
    const existingResults = existing?.results || [];
    const { results: incomingResults, meta } = incoming;

    const results = addIncomingToExistingResults({
        existingResults,
        incomingResults,
        offset: args.offset,
    });

    return { results, meta };
}

export function readPaginatedQuery(existing) {
    return existing;
}

function addIncomingToExistingResults({
    existingResults,
    incomingResults,
    offset,
}) {
    const newResults =
        offset > 0
            ? uniqueBy([...existingResults, ...incomingResults], "__ref")
            : incomingResults;

    return newResults;
}
