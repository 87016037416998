import {
    readQuery,
    writeQuery,
    removeEntitiesFromPaginatedQuery,
    updateEntityInCache,
} from "~graphql/cache/shared";
import { COMPANY_USER_FRAGMENT } from "~graphql/mutations/userCompanyRoles";
import {
    GET_CURRENT_USER,
    GET_COMPANY_USERS,
    GET_COMPANY_ENTITY_USERS,
} from "~graphql/queries/users";

import { USER_COMPANY_ROLES, DEFAULT_LIMITS, DEFAULT_SORT } from "~constants";

export function updateUserCache({ cache, update }) {
    const user = getUserFromCache({ cache });
    updateUserCacheCore({ cache, update, user });
}

export function updateCurrentUserCompany({ cache, updatedCompany }) {
    const user = getUserFromCache({ cache });
    const existingCompanies = user.companies || [];

    const updatedCompanies = existingCompanies.map((company) => {
        // Since WorkOn user.companies can have fake billing info, we
        // don't want to overwrite that. The only prop from the company
        // we need to update on user companies is the name.
        return company.id === updatedCompany.id
            ? { ...company, name: updatedCompany.name }
            : company;
    });

    const update = { companies: updatedCompanies };

    updateUserCacheCore({ cache, update, user });
}

// This function supports both companies and user companies. Which is why
// we are mapping all of the props with defaults. When accepting entity
// invitations, the user may already have a company role. Need to handle duplicates.
export function addCompanyToUserCache({ cache, company }) {
    const user = getUserFromCache({ cache });
    const foundCompany = user.companies?.find(({ id }) => id === company.id);

    // If the user already has the company, don't add it.
    if (foundCompany) return;

    const userCompany = mapCompanyOrUserCompanyToUserCompany(company);
    const updatedCompanies = [...user.companies, userCompany];
    const update = { companies: updatedCompanies };

    updateUserCacheCore({ cache, update, user });
}

export function updateCompanyInUserCache({ cache, data, companyId }) {
    const user = getUserFromCache({ cache });
    const updatedCompanies = user.companies.map((company) =>
        company.id !== companyId ? company : { ...company, ...data }
    );
    const update = { companies: updatedCompanies };

    updateUserCacheCore({ cache, update, user });
}

export function updateUserCacheCore({ cache, update, user }) {
    const updatedUser = { ...user, ...update };

    writeQuery({
        ...getDefaultUserCacheQueryArgs(cache),
        update: updatedUser,
    });
}

export function updateUserFromCompanyUsersCache({ cache, userId, update }) {
    updateEntityInCache({
        cache,
        typeName: "CompanyUser",
        fragment: COMPANY_USER_FRAGMENT,
        entityId: userId,
        update,
    });
}

export function removeUsersFromCompanyUsersCache({
    cache,
    companyId,
    userIdList,
}) {
    removeEntitiesFromPaginatedQuery({
        cache,
        query: GET_COMPANY_USERS,
        variables: {
            companyId,
            limit: DEFAULT_LIMITS.COMPANY_USERS,
            offset: 0,
            sort: DEFAULT_SORT,
            searchKeyword: null,
        },
        pathToData: "getCompanyUsers",
        entityIdList: userIdList,
    });
}

export function removeUsersFromCompanyEntityUsersCache({
    cache,
    companyId,
    entityId,
    userIdList,
}) {
    removeEntitiesFromPaginatedQuery({
        cache,
        query: GET_COMPANY_ENTITY_USERS,
        variables: { companyId, entityId, offset: 0, searchKeyword: null },
        pathToData: "getCompanyEntityUsers",
        entityIdList: userIdList,
    });
}

function getUserFromCache({ cache }) {
    const queryArgs = getDefaultUserCacheQueryArgs(cache);

    return readQuery(queryArgs);
}

function getDefaultUserCacheQueryArgs(cache) {
    return {
        cache,
        query: GET_CURRENT_USER,
        pathToData: "getCurrentUser",
    };
}

// Many of these props will be missing when passed a regular company.
// However, it's only used for this after creating a company, so
// we know what the missing values should be.
function mapCompanyOrUserCompanyToUserCompany(company) {
    return {
        id: company.id,
        name: company.name,
        role: company.role || USER_COMPANY_ROLES.OWNER,
        subscription_id: company.subscription_id || null,
        expiration_expires_at: company.expiration_expires_at || null,
        features: company.features || [],
        feature_groups: company.feature_groups || [],
        can_billing_be_managed: company.can_billing_be_managed || true,
        is_pay_as_you_go: company.is_pay_as_you_go,
    };
}
