import Avatar from "@mui/material/Avatar";
import PropTypes from "prop-types";
import * as React from "react";

export default function BasicAvatar({ w, h, imageUrl, alt, ...props }) {
    const size = { width: w, height: h };
    const avatarProps = {
        alt,
        src: imageUrl,
        sx: size,
        ...props,
    };

    if (!imageUrl && alt) {
        const initials = getFirstAndLastInitials(alt);

        return <Avatar {...avatarProps}>{initials}</Avatar>;
    }

    return <Avatar {...avatarProps} />;
}

BasicAvatar.propTypes = {
    w: PropTypes.number,
    h: PropTypes.number,
    imageUrl: PropTypes.string,
    alt: PropTypes.string,
};

export function getFirstAndLastInitials(name) {
    if (!name) return "";

    const initialsArray = name
        .split(" ")
        .map((part) => (part[0] || "").toUpperCase());
    const firstInitial = initialsArray[0];
    const lastInitial = initialsArray[initialsArray.length - 1];

    return initialsArray.length > 1
        ? `${firstInitial}${lastInitial}`
        : firstInitial;
}
