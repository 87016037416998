import styled from "@emotion/styled";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState, useRef } from "react";

import { TEST_IDS } from "~app/constants";
import { useCurrentCompany } from "~graphql/hooks/currentCompany";
import { useUser, useIsInternalUser } from "~graphql/hooks/users";
import CompanyAvatar from "~shared/Avatar/CompanyAvatar";

export default function NavBarSwitchCompanyMenu() {
    const [isOpen, setIsOpen] = useState(false);

    const { user } = useUser();
    const { isInternalUser } = useIsInternalUser();
    const { companyId, setCurrentCompany } = useCurrentCompany();

    const anchorRef = useRef();
    const userCompanies = user?.companies || [];

    if (isInternalUser || userCompanies.length < 2) return null;

    return (
        <>
            <MenuItem
                data-testid={TEST_IDS.SWITCH_COMPANY_BUTTON}
                ref={anchorRef}
                color="secondary"
                onClick={() => setIsOpen((val) => !val)}
                divider
            >
                <ListItemText>Switch Company</ListItemText>
            </MenuItem>
            <StyledMenu
                anchorEl={anchorRef.current}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                {userCompanies.map(({ id, name }) => (
                    <MenuItem
                        key={id}
                        onClick={() => setCurrentCompany({ id, name })}
                        selected={companyId === id}
                    >
                        <ListItemIcon>
                            <CompanyAvatar id={id} />
                        </ListItemIcon>
                        <ListItemText>{name}</ListItemText>
                    </MenuItem>
                ))}
            </StyledMenu>
        </>
    );
}

const StyledMenu = styled(Menu)({
    "& .MuiMenu-list": {
        padding: 0,
        minWidth: 200,
    },
});
