import { ErrorMessage } from "@boomnation/lib-web-common";
import React from "react";

import LogoutButton from "~shared/Button/LogoutButton";

export default function ErrorMessageWithLogoutButton(props) {
    return <ErrorMessage {...props} ButtonComponent={StyledLogoutButton} />;
}

function StyledLogoutButton(props) {
    return <LogoutButton variant="contained" {...props} />;
}
