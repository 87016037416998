import { gql } from "@apollo/client";

import { COMPANY_SCHEMA, COMPANY_INVITATION } from "~graphql/schemas/companies";

export const CREATE_COMPANY = gql`
    mutation createCompany($company: CreateCompanyInput!) {
        createCompany(company: $company) {
            ${COMPANY_SCHEMA}
        }
    }
`;

export const IMPORT_COMPANY_FROM_HUBSPOT = gql`
    mutation importCompanyFromHubspot($hubspotCompanyId: String!) {
        importCompanyFromHubspot(hubspotCompanyId: $hubspotCompanyId) {
            ${COMPANY_SCHEMA}
            expiration_expires_at
            hubspot_id
        }
    }
`;

export const UPDATE_COMPANY = gql`
    mutation updateCompany($id: UUID!, $update: UpdateCompanyInput!) {
        updateCompany(id: $id, update: $update) {
            ${COMPANY_SCHEMA}
        }
    }
`;

export const DELETE_COMPANY = gql`
    mutation Mutation($id: UUID!) {
        deleteCompany(id: $id)
    }
`;

export const ACCEPT_COMPANY_INVITATION = gql`
    mutation AcceptCompanyInvitation($invitationId: UUID!) {
        acceptCompanyInvitation(invitationId: $invitationId) {
            expiration_expires_at
            id
            name
            role
            subscription_id
            is_pay_as_you_go
        }
    }
`;

export const CANCEL_COMPANY_INVITATION = gql`
    mutation Mutation($invitationId: UUID!) {
        cancelCompanyInvitation(invitationId: $invitationId) {
            ${COMPANY_INVITATION}
        }
    }
`;

export const INVITE_USERS_TO_COMPANY = gql`
    mutation InviteUsersToCompany(
        $companyId: UUID!
        $entityType: String
        $entityId: UUID
        $entityIdList: [UUID!]
        $invitations: [CompanyInvitationInput!]!
        $customMessage: String
        $shouldRemoveUnspecifiedUsers: Boolean
        $shouldUseWelcomeUrl: Boolean
    ) {
        inviteUsersToCompany(
            companyId: $companyId
            entityType: $entityType
            entityId: $entityId
            entityIdList: $entityIdList
            invitations: $invitations
            customMessage: $customMessage
            shouldRemoveUnspecifiedUsers: $shouldRemoveUnspecifiedUsers
            shouldUseWelcomeUrl: $shouldUseWelcomeUrl
        ) {
            ${COMPANY_INVITATION}
        }
    }
`;

export const RESEND_COMPANY_INVITATIONS = gql`
    mutation Mutation(
        $invitationIds: [UUID!]!
        $companyId: UUID!
        $entityType: String
        $customMessage: String
    ) {
        resendCompanyInvitations(
            invitationIds: $invitationIds
            companyId: $companyId
            entityType: $entityType
            customMessage: $customMessage
        )
    }
`;
