/* eslint max-lines: ["error", {"max": 350}] */
import { makeVar, useReactiveVar } from "@apollo/client";
import { makeArrayIfNotArray } from "@boomnation/lib-common";

import { ENTITY_TYPES } from "~app/constants";

const LOCAL_STATE_KEYS = {
    JOBS: "JOBS",
    INDUSTRIES: "INDUSTRIES",
    COMPANIES: "COMPANIES",
    COMPANY_ENTITY_USERS: "COMPANY_ENTITY_USERS",
    COMPANY_INVITATIONS: "COMPANY_INVITATIONS",
    COMPANY_USERS: "COMPANY_USERS",
    PROJECTS: "PROJECTS",
    BULLETIN_POST_TEMPLATES: "BULLETIN_POST_TEMPLATES",
    CONTENT_BLOCK_TEMPLATES: "CONTENT_BLOCK_TEMPLATES",
    FORM_TEMPLATES: "FORM_TEMPLATES",
};

export const DEFAULT_ENTITY_STATE = {
    updatesById: {},
    deletedIdList: [],
};

// Keyed by local state keys.
// Entity state: { updatedsById: {}, deletedIdList: [] }
const localStateVar = makeVar(getDefaultLocalState());

export function useLocalJobsState() {
    const {
        entitiesState,
        addDeletedEntity,
        addEntityUpdate,
        applyLocalStateToEntities,
        resetEntityState,
    } = useLocalEntityState(LOCAL_STATE_KEYS.JOBS);

    return {
        localJobsState: entitiesState,
        addDeletedJob: addDeletedEntity,
        addJobUpdate: addEntityUpdate,
        applyLocalStateToJobsList: applyLocalStateToEntities,
        reset: resetEntityState,
    };
}

export function useLocalIndustriesState() {
    const {
        entitiesState,
        addDeletedEntity,
        addEntityUpdate,
        applyLocalStateToEntities,
        resetEntityState,
    } = useLocalEntityState(LOCAL_STATE_KEYS.INDUSTRIES);

    return {
        localJobsState: entitiesState,
        addDeletedIndustry: addDeletedEntity,
        addIndustryUpdate: addEntityUpdate,
        applyLocalStateToIndustriesList: applyLocalStateToEntities,
        reset: resetEntityState,
    };
}

export function useLocalCompanyEntityUsersState() {
    const {
        entitiesState,
        addDeletedEntity,
        addDeletedEntities,
        addEntityUpdate,
        applyLocalStateToEntities,
        resetEntityState,
    } = useLocalEntityState(LOCAL_STATE_KEYS.COMPANY_ENTITY_USERS);

    return {
        localCompanyEntityUsersState: entitiesState,
        addDeletedCompanyEntityUser: addDeletedEntity,
        addDeletedCompanyEntityUsers: addDeletedEntities,
        addCompanyEntityUserUpdate: addEntityUpdate,
        applyLocalStateToCompanyEntityUsersList: applyLocalStateToEntities,
        reset: resetEntityState,
    };
}

export function useLocalCompanyUsersState() {
    const {
        entitiesState,
        addDeletedEntity,
        addDeletedEntities,
        addEntityUpdate,
        applyLocalStateToEntities,
        resetEntityState,
    } = useLocalEntityState(LOCAL_STATE_KEYS.COMPANY_USERS);

    return {
        localCompanyUsersState: entitiesState,
        addDeletedCompanyUser: addDeletedEntity,
        addDeletedCompanyUsers: addDeletedEntities,
        addCompanyUserUpdate: addEntityUpdate,
        applyLocalStateToCompanyUsersList: applyLocalStateToEntities,
        reset: resetEntityState,
    };
}

export function useLocalCompanyInvitationsState() {
    const {
        entitiesState,
        addDeletedEntity,
        addEntityUpdate,
        applyLocalStateToEntities,
        resetEntityState,
    } = useLocalEntityState(LOCAL_STATE_KEYS.COMPANY_INVITATIONS);

    return {
        localCompanyInvitationsState: entitiesState,
        addDeletedCompanyInvitation: addDeletedEntity,
        addCompanyInvitationUpdate: addEntityUpdate,
        applyLocalStateToCompanyInvitationsList: applyLocalStateToEntities,
        reset: resetEntityState,
    };
}

export function useLocalProjectsState() {
    const {
        entitiesState,
        addDeletedEntity,
        addEntityUpdate,
        applyLocalStateToEntities,
        resetEntityState,
    } = useLocalEntityState(LOCAL_STATE_KEYS.PROJECTS);

    return {
        localProjectsState: entitiesState,
        addDeletedProject: addDeletedEntity,
        addProjectUpdate: addEntityUpdate,
        applyLocalStateToProjectsList: applyLocalStateToEntities,
        reset: resetEntityState,
    };
}

export function useLocalCompaniesState() {
    const {
        entitiesState,
        addDeletedEntity,
        addEntityUpdate,
        applyLocalStateToEntities,
        resetEntityState,
    } = useLocalEntityState(LOCAL_STATE_KEYS.COMPANIES);

    return {
        localCompaniesState: entitiesState,
        addDeletedCompany: addDeletedEntity,
        addCompanyUpdate: addEntityUpdate,
        applyLocalStateToCompaniesList: applyLocalStateToEntities,
        reset: resetEntityState,
    };
}

export function useLocalTemplatesState(entityType) {
    const localStateKey = getTemplateStateKeyForEntityType({ entityType });
    const {
        entitiesState,
        addDeletedEntity,
        addEntityUpdate,
        applyLocalStateToEntities,
        resetEntityState,
    } = useLocalEntityState(localStateKey);

    return {
        localJobsState: entitiesState,
        addDeletedTemplate: addDeletedEntity,
        addTemplateUpdate: addEntityUpdate,
        applyLocalStateToTemplatesList: applyLocalStateToEntities,
        reset: resetEntityState,
    };
}

function useLocalEntityState(key) {
    const localState = useReactiveVar(localStateVar);
    const entitiesState = localState[key];

    return {
        entitiesState,
        addDeletedEntity: (id) =>
            addDeletedEntityOrEntitiesToState({
                idOrIdList: id,
                entitiesState,
                key,
            }),
        addDeletedEntities: (idList) =>
            addDeletedEntityOrEntitiesToState({
                idOrIdList: idList,
                entitiesState,
                key,
            }),
        addEntityUpdate: ({ id, update }) =>
            addEntityUpdateToState({ id, update, entitiesState, key }),
        applyLocalStateToEntities: (entityList) =>
            applyLocalStateToEntityList({ entityList, entitiesState }),
        resetEntityState: () => resetLocalEntityState({ key }),
    };
}

// Helpers

function addDeletedEntityOrEntitiesToState({ idOrIdList, entitiesState, key }) {
    const { deletedIdList } = entitiesState;

    const idList = makeArrayIfNotArray(idOrIdList);

    const updatedEntityState = {
        ...entitiesState,
        deletedIdList: [...deletedIdList, ...idList],
    };

    updateLocalEntityState({ key, entityState: updatedEntityState });
}

function addEntityUpdateToState({ id, update, entitiesState, key }) {
    const currentUpdatesForEntity = entitiesState.updatesById[id] || [];
    const updatedUpdatesById = {
        ...entitiesState.updatesById,
        [id]: [...currentUpdatesForEntity, update],
    };

    const updatedEntityState = {
        ...entitiesState,
        updatesById: updatedUpdatesById,
    };

    updateLocalEntityState({ key, entityState: updatedEntityState });
}

function applyLocalStateToEntityList({ entityList = [], entitiesState }) {
    const filteredEntityList = filterOutDeletedEntities({
        entityList,
        entitiesState,
    });
    const entityListWithUpdates = applyUpdatesToEntities({
        entityList: filteredEntityList,
        entitiesState,
    });

    return entityListWithUpdates;
}

function applyUpdatesToEntities({ entityList = [], entitiesState }) {
    return entityList.map((entity) =>
        applyUpdatesToEntity({ entity, entitiesState })
    );
}

function applyUpdatesToEntity({ entity, entitiesState }) {
    const { updatesById } = entitiesState;
    const updatesForEntity = updatesById[entity.id] || [];

    return updatesForEntity.reduce(
        (updatedEntity, update) => ({ ...updatedEntity, ...update }),
        entity
    );
}

function filterOutDeletedEntities({ entityList, entitiesState }) {
    const { deletedIdList } = entitiesState;

    return entityList.filter((entity) => !deletedIdList.includes(entity.id));
}

function getDefaultLocalState() {
    const keys = Object.keys(LOCAL_STATE_KEYS);

    return keys.reduce(
        (defaultState, key) => ({
            ...defaultState,
            [key]: DEFAULT_ENTITY_STATE,
        }),
        {}
    );
}

function updateLocalEntityState({ key, entityState }) {
    const localState = localStateVar();

    localStateVar({ ...localState, [key]: entityState });
}

function resetLocalEntityState({ key }) {
    updateLocalEntityState({ key, entityState: DEFAULT_ENTITY_STATE });
}

function getTemplateStateKeyForEntityType({ entityType }) {
    switch (entityType) {
        case ENTITY_TYPES.BULLETIN_POSTS:
            return LOCAL_STATE_KEYS.BULLETIN_POST_TEMPLATES;
        case ENTITY_TYPES.CONTENT_BLOCKS:
            return LOCAL_STATE_KEYS.CONTENT_BLOCK_TEMPLATES;
        case ENTITY_TYPES.FORMS:
        default:
            return LOCAL_STATE_KEYS.FORM_TEMPLATES;
    }
}
