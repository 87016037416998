import {
    ENTITY_LINK_TYPES,
    ENTITY_QUERY_STRING_PARAMETER_ABBREVIATIONS,
} from "@boomnation/lib-web-common";
import { useHistory } from "react-router-dom";

import { useLocationQuery } from "~app/hooks/utilHooks";

import { ROUTES } from "~constants";

export const MESSAGING_USER_ID = "messaging_user_id";

export function useNavigationHelpers() {
    const history = useHistory();

    return {
        goToMessagesPreview: createWrappedGoTo(history, goToMessagesPreview),
        goToJob: createWrappedGoTo(history, goToJob),
        goToJobActionPage: createWrappedGoTo(history, goToJobActionPage),
        goToMessages: createWrappedGoTo(history, goToMessages),
        goToJobs: createWrappedGoTo(history, goToJobs),
        goToDashboard: createWrappedGoTo(history, goToDashboard),
        goToAddNew: createWrappedGoTo(history, goToAddNew),
        goToPartner: createWrappedGoTo(history, goToPartner),
        goToPage: createWrappedGoTo(history, goToPage),
        goToProject: createWrappedGoTo(history, goToProject),
        goToEditContentBlock: createWrappedGoTo(history, goToEditContentBlock),
        goToNewContentBlock: createWrappedGoTo(history, goToNewContentBlock),
        goToNewCampaign: createWrappedGoTo(history, goToNewCampaign),
        goToCampaign: createWrappedGoTo(history, goToCampaign),
        goToCampaignCandidates: createWrappedGoTo(
            history,
            goToCampaignsCandidates
        ),
    };
}

function createWrappedGoTo(history, goTo) {
    return (params) => goTo({ ...params, history });
}

// TODO this could be named better
function goToMessagesPreview({ userId, history }) {
    history.push({
        pathname: ROUTES.MESSAGES,
        search: `?${MESSAGING_USER_ID}=${userId}`,
    });
}

export function useMessagingUserId() {
    const history = useHistory();
    const query = useLocationQuery();
    const messagingUserId = query.get(MESSAGING_USER_ID);

    const setMessagingUserId = (userId) => {
        goToMessagesPreview({ userId, history });
    };

    return [messagingUserId, setMessagingUserId];
}

function goToJob({ jobId, history, goBackRoute }) {
    history.push({
        pathname: `${ROUTES.JOBS}/${jobId}`,
        state: { goBackRoute },
    });
}

function goToJobActionPage({ route, id, goBackRoute, history }) {
    history.push({
        pathname: `${ROUTES.JOBS}/${route}`,
        state: { id, goBackRoute },
    });
}

function goToMessages({ history }) {
    history.push(ROUTES.MESSAGES);
}

function goToJobs({ history }) {
    history.push(ROUTES.JOBS);
}

function goToDashboard({ history }) {
    history.push(ROUTES.DASHBOARD);
}

function goToAddNew({ history, ...state }) {
    history.push({ pathname: ROUTES.NEW_JOB, state });
}

function goToPartner({ route, history }) {
    history.push({ pathname: `${ROUTES.PARTNERS}/${route}` });
}

function goToPage({ route, history }) {
    history.push(route);
}

function goToProject({ route, goBackRoute, history }) {
    history.push({
        pathname: `${ROUTES.PROJECTS}/${route}`,
        state: { goBackRoute },
    });
}

function goToEditContentBlock({
    history,
    contentBlockId,
    entityId,
    entityType,
    companyId,
}) {
    history.push({
        pathname: `${ROUTES.CONTENT_BLOCKS}/${contentBlockId}/edit`,
        search: getContentBlockSearchParams({
            entityId,
            entityType,
            companyId,
        }),
    });
}

function goToNewCampaign({ history, goBackRoute }) {
    history.push({
        pathname: ROUTES.NEW_CAMPAIGN,
        state: { goBackRoute },
    });
}

function goToCampaign({ history }) {
    history.push(ROUTES.CAMPAIGNS);
}

function goToCampaignsCandidates({ id, route, goBackRoute, history }) {
    history.push({
        pathname: `${ROUTES.CAMPAIGNS}/${route}`,
        state: { id, goBackRoute },
    });
}

function goToNewContentBlock({ history, entityId, entityType, companyId }) {
    history.push({
        pathname: ROUTES.NEW_CONTENT_BLOCKS,
        search: getContentBlockSearchParams({
            entityId,
            entityType,
            companyId,
        }),
    });
}

export function getContentBlockSearchParams({
    entityId,
    entityType,
    companyId,
}) {
    return `?company_id=${companyId}&entity_id=${entityId}&entity_type=${entityType}`;
}

export function getCurrentCompanyRoute({ companyId }) {
    return `${ROUTES.PARTNERS}/${companyId}`;
}

export function getWorkerWebJobUrl({ jobId }) {
    return `${process.env.WORKER_WEB_URL}/jobs/${jobId}`;
}

export function getWorkerWebProjectUrl({ projectId, linkType }) {
    const url = getWorkerWebProjectUrlWithoutQueryParams({ projectId });
    const paramValue = getWorkerWebProjectUrlLinkTypeParams({ linkType });
    const param = `${ENTITY_QUERY_STRING_PARAMETER_ABBREVIATIONS.TYPE_KEY}=${paramValue}`;

    return `${url}?${param}`;
}

export function getWorkerWebCampaignFormUrl({ campaignId, formId }) {
    return `${process.env.WORKER_WEB_URL}/campaigns/${campaignId}/form/${formId}`;
}

export function getWorkerWebProjectUrlWithoutQueryParams({ projectId }) {
    return `${process.env.WORKER_WEB_URL}/projects/${projectId}`;
}

export function getWorkerWebProjectUrlLinkTypeParams({ linkType }) {
    switch (linkType) {
        case ENTITY_LINK_TYPES.QR_CODE:
            return ENTITY_QUERY_STRING_PARAMETER_ABBREVIATIONS.TYPE_VALUE_QR;
        case ENTITY_LINK_TYPES.LINK:
            return ENTITY_QUERY_STRING_PARAMETER_ABBREVIATIONS.TYPE_VALUE_LINK;
        default:
            return ENTITY_QUERY_STRING_PARAMETER_ABBREVIATIONS.TYPE_VALUE_LINK;
    }
}

export function getJobUrl({ jobId }) {
    return `${ROUTES.JOBS}/${jobId}`;
}
