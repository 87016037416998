import { Button, THEME_MODES } from "@boomnation/lib-web-common";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTranslation } from "react-i18next";

import { TEST_IDS } from "~app/constants";
import { useIsDarkMode } from "~app/hooks/utilHooks";
import { useCurrentCompany } from "~graphql/hooks/currentCompany";
import Logo, { VARIANTS } from "~shared/Logo/Logo";

const TITLE = "Hey there... looks like you're in the wrong place";
const MESSAGE =
    "This area is for employers only. Want to go check out our worker app?";
const BUTTON_LABEL = "Let's go";

export default function CompanyWorkerMessage() {
    const { t } = useTranslation();

    // Don't need to wait for loading since App.js already waited. useCurrentCompany
    // defaults to first company on the user if it hasn't been set using the selector.
    const { companyId } = useCurrentCompany();

    const isDarkMode = useIsDarkMode();
    const logoVariant = isDarkMode
        ? VARIANTS.WORKON_LOGO
        : VARIANTS.WORKON_LOGO_COLOR;
    const workerWebUrl = getWorkerWebUrl({ companyId });

    return (
        <Container data-testid={TEST_IDS.WORKER_MESSAGE}>
            <StyledCard>
                <Typography variant="h4">{t(TITLE)}</Typography>
                <Typography>{t(MESSAGE)}</Typography>
                <StyledButton variant="contained" href={workerWebUrl}>
                    {t(BUTTON_LABEL)}
                </StyledButton>
            </StyledCard>
            <StyledLogo variant={logoVariant} />
        </Container>
    );
}

const Container = styled(Box)(({ theme }) => ({
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    gap: 100,
}));

const StyledCard = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: 20,
    maxWidth: "370px",
    padding: "25px",
    textAlign: "center",
    borderRadius: theme.borderRadius.md,
    boxShadow: `0 2px 3px 0 rgba(0, 0, 0, 0.3)`,
    backgroundColor:
        theme.palette.mode === THEME_MODES.DARK
            ? theme.palette.card.secondaryBackground
            : theme.palette.text.light,
}));

const StyledButton = styled(Button)({
    textTransform: "none",
    padding: "6px 20px !important",
    boxShadow: "none",
});

const StyledLogo = styled(Logo)({
    maxWidth: "250px",
    margin: 0,
});

// There should always be a companyId. This component will only render if the user
// has a worker role a company. If a user doesn't have any companies, they will be
// redirected to post signup by the protected route component.
function getWorkerWebUrl({ companyId }) {
    return `${process.env.WORKER_WEB_URL}/companies/${companyId}/projects`;
}
