export const COMPANY_SCHEMA = `
    id
    name
    phone_number
    website_url
    bio
    image_url
    cover_url
    address {
        id,
        line_1
        city
        state
        zip
        latitude
        longitude
    }
    company_photos
    is_archived
    subscription_id
    expiration_expires_at
    is_pay_as_you_go
    hubspot_id
    created_at
    updated_at
`;

export const COMPANY_INVITATION = `
    id
    company_id
    inviter_id
    name
    phone
    email
    role
    expires_at
    created_at
    updated_at
`;
