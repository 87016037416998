import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";

import { useScreenSize } from "~app/hooks/utilHooks";
import { NavBarContext } from "~app/Providers/NavBarProvider";
import NavBarMenuButton from "~shared/NavBar/NavBarMenuButton";

import { HEIGHT, TEST_IDS } from "~constants";

export default function NavBar() {
    const { isMdScreen } = useScreenSize();
    const { pathname } = useLocation();
    const { setSideBarOpen } = useContext(NavBarContext);
    const shouldShowMenuBtn = isMdScreen;

    return (
        <StyledNavBar>
            <StyledToolBar>
                <FlexStartBox>
                    {shouldShowMenuBtn && (
                        <IconButton
                            onClick={() => setSideBarOpen(true)}
                            color="secondary"
                            data-testid={TEST_IDS.TOP_NAV_SIDEBAR_BUTTON}
                        >
                            <MenuIcon fontSize="large" />
                        </IconButton>
                    )}
                </FlexStartBox>
                <FlexEndBox>
                    {!isMdScreen && shouldShowSearchBar(pathname) && (
                        <StyledSearchBox />
                    )}
                    <NavBarMenuButton />
                </FlexEndBox>
            </StyledToolBar>
        </StyledNavBar>
    );
}

function shouldShowSearchBar(pathname) {
    // only show search bar if the route is not nested (Only has initial forward slash
    return !pathname.includes("/", 1);
}

const StyledNavBar = styled(AppBar, { shouldForwardProp: isPropValid })(
    ({ theme }) => ({
        backgroundColor: "transparent",
        borderTopLeftRadius: theme.borderRadius.md,
        boxShadow: "none",
        backgroundImage: "none",
        width: "100%",
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: theme.zIndex.appBar,
        [theme.breakpoints.up("md")]: {
            width: "auto",
        },
    })
);

const StyledToolBar = styled(Toolbar, { shouldForwardProp: isPropValid })(
    ({ theme }) => ({
        [theme.breakpoints.between("xs", "md")]: {
            marginTop: 10,
            height: "auto",
        },
        height: HEIGHT.NAVBAR,
    })
);

const FlexStartBox = styled(Box)({
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
});

const FlexEndBox = styled(Box)({
    flexGrow: 0,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
});

const StyledSearchBox = styled(Box)(({ theme }) => ({
    width: "auto",
    marginRight: theme.spacing(1),
}));
