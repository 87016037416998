import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import React from "react";
import { Redirect } from "react-router-dom";

import { getRedirectAppState } from "~app/authRedirectionHelpers";
import { ROUTES, ACCOUNT_SETTINGS_STEP_IDS, LOGIN_TYPE } from "~app/constants";
import {
    useIsAuthenticated,
    useHasBeenAuthenticated,
} from "~app/hooks/authHooks";
import { hasCompletedSignup } from "~app/postSignupHelpers";
import RouteWithPageAnalytics from "~app/RouteWithPageAnalytics";
import { useCurrentCompany } from "~graphql/hooks/currentCompany";
import { useUser } from "~graphql/hooks/users";

export default function ProtectedRouteWithPageAnalytics(props) {
    const { loginWithRedirect } = useAuth0();
    const {
        shouldRedirectToLogin,
        shouldRedirectToSettings,
        shouldRedirectToSignup,
    } = useShouldRedirect(props.path);

    if (shouldRedirectToLogin) {
        return (
            <Redirect
                to={loginWithRedirect({
                    appState: getRedirectAppState(),
                    // If null/undefined is passed here, universal login will default to sms.
                    login_type: LOGIN_TYPE.EMAIL,
                    // Can not pass false here (use undefined or true). These args are stringified.
                    disable_login_type_switching:
                        process.env.DISABLE_LOGIN_TYPE_SWITCHING,
                })}
            />
        );
    }

    if (shouldRedirectToSignup) {
        return <Redirect to={getPostSignupRedirectTo()} />;
    }

    if (shouldRedirectToSettings) {
        return (
            <Redirect
                to={{
                    pathname: ROUTES.SETTINGS,
                    state: {
                        startingStepId: ACCOUNT_SETTINGS_STEP_IDS.BILLING,
                    },
                }}
            />
        );
    }

    return <RouteWithPageAnalytics {...props} />;
}

ProtectedRouteWithPageAnalytics.propTypes = {
    isAuthenticated: PropTypes.bool,
    path: PropTypes.string,
};

function useShouldRedirect(currentRoutePath) {
    const isAuthenticated = useIsAuthenticated();
    const hasBeenAuthenticated = useHasBeenAuthenticated();
    const { user } = useUser();
    const { companyId, isSubscriptionActive, isLoading } = useCurrentCompany();

    // If user is internal, do not redirect anywhere
    if (user?.is_internal) {
        return {
            shouldRedirectToLogin: false,
            shouldRedirectToSignup: false,
            shouldRedirectToSettings: false,
        };
    }

    const isSignupCompleted = hasCompletedSignup({ user, companyId });

    const isSignupRoute = currentRoutePath === ROUTES.POST_SIGNUP;
    const isSettingsRoute = currentRoutePath === ROUTES.SETTINGS;
    const isSubscriptionExpired = !isSubscriptionActive && isSignupCompleted;

    const shouldRedirectToLogin = !isAuthenticated && !hasBeenAuthenticated;
    const shouldRedirectToSignup = !isSignupCompleted && !isSignupRoute;
    const shouldRedirectToSettings = isSubscriptionExpired && !isSettingsRoute;

    return {
        shouldRedirectToLogin: !isLoading && shouldRedirectToLogin,
        shouldRedirectToSignup: !isLoading && shouldRedirectToSignup,
        shouldRedirectToSettings: !isLoading && shouldRedirectToSettings,
    };
}

// TODO: Put this back in when we figure out a good way to handle it without the landing page.
// function getLandingRedirectReferrer() {
//     return {
//         returnTo: window.location.pathname,
//         searchString: window.location.search,
//     };
// }

// Includes any search params to preserve things like invitation codes.
function getPostSignupRedirectTo() {
    return { pathname: ROUTES.POST_SIGNUP, search: window.location.search };
}
