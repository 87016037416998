/* eslint-disable react/jsx-max-depth */
import {
    LibWebCommonProviders,
    getAddress,
    usePlacesAutocomplete,
} from "@boomnation/lib-web-common";
import { createBrowserHistory } from "history";
import PropTypes from "prop-types";
import React from "react";
import { Router } from "react-router-dom";

import {
    useAttachmentImage,
    useDownloadAttachmentsZip,
} from "~app/hooks/cdnHooks";
import ApolloProvider from "~app/Providers/ApolloProvider";
import AuthProvider from "~app/Providers/AuthProvider";
import FeatureFlagProvider from "~app/Providers/FeatureFlagProvider";
import NavBarProvider from "~app/Providers/NavBarProvider";
import TableStateProvider from "~app/Providers/TableStateProvider";
import { useUser } from "~graphql/hooks/users";
import analytics from "~services/analytics";
import { logError } from "~services/sentry";

import { HEIGHT } from "~constants";

const history = createBrowserHistory();

export default function Providers({ children }) {
    const util = {
        logError,
        useAttachmentImage,
        getAddress,
        usePlacesAutocomplete,
        useCurrentUser: useUser,
        useDownloadAttachmentsZip,
        analytics,
        workerWebUrl: process.env.WORKER_WEB_URL,
    };
    const snackBar = {
        marginTop: HEIGHT.NAVBAR,
    };

    return (
        <AuthProvider history={history}>
            <NavBarProvider>
                <LibWebCommonProviders util={util} snackBar={snackBar}>
                    <ApolloProvider>
                        <FeatureFlagProvider>
                            <TableStateProvider>
                                <Router history={history}>{children}</Router>
                            </TableStateProvider>
                        </FeatureFlagProvider>
                    </ApolloProvider>
                </LibWebCommonProviders>
            </NavBarProvider>
        </AuthProvider>
    );
}

Providers.propTypes = {
    children: PropTypes.any,
};
