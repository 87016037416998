import { useUser, useCompanyUserSupportChatToken } from "~graphql/hooks/users";
import { openChat } from "~services/hubspot";

let lastUserId;

export function useHubspotChat() {
    const { user } = useUser();
    const shouldSkipToken = !user || user.is_internal;

    const { supportChatToken } = useCompanyUserSupportChatToken({
        user,
        shouldSkip: shouldSkipToken,
    });

    // Only attempt to open chat once if we have a token and a user
    if (!shouldSkipToken && supportChatToken && lastUserId !== user.id) {
        lastUserId = user.id;
        openChat({ token: supportChatToken, email: user.email });
    }
}
