/* eslint-disable max-lines */
// todo: https://gitlab.com/boomnation/web-app-hiring-boomnation/-/issues/531

export * from "~app/muiConstants";

export const TEST_IDS = {
    APP: "APP",
    APP_UNAUTHENTICATED: "APP_UNAUTHENTICATED",
    MAIN_CONTENT: "MAIN_CONTENT",
    MAIN_CONTENT_MOBILE: "MAIN_CONTENT_MOBILE",
    PAGE_PREFIX: "PAGE",
    NOT_FOUND: "NOT_FOUND",
    LOADING_INDICATOR: "LOADING_INDICATOR",
    EMPTY_ROWS: "EMPTY_ROWS",
    LOGOUT: "LOGOUT",
    LOGIN: "LOGIN",
    WORKON_LOGO: "WORKON_LOGO",
    BN_LOGO: "BN_LOGO",
    BN_LOGO_WHITE: "BN_LOGO_WHITE",
    WORKON_LOGO_COLOR: "WORKON_LOGO_COLOR",
    BM_APP_LOGO: "BM_APP_LOGO",
    WORKON_BADGE: "WORKON_BADGE",
    COMPANY_INFO_SCREEN: "COMPANY_INFO_SCREEN",
    SIDEBAR_BUTTON: "SIDEBAR_BUTTON",
    TOP_NAV_SIDEBAR_BUTTON: "TOP_NAV_SIDEBAR_BUTTON",
    SIDEBAR: "SIDEBAR",
    ADD_BUTTON: "ADD_BUTTON",
    EDIT_BUTTON: "EDIT_BUTTON",
    REMOVE_BUTTON: "REMOVE_BUTTON",
    GO_BACK_BUTTON: "BACK_BUTTON",
    CLOSE_BUTTON: "CLOSE_BUTTON",
    DOWNLOAD_BUTTON: "DOWNLOAD_BUTTON",
    TABLE_CARD: "TABLE_CARD",
    CARD: "CARD",
    SUBTITLE: "SUBTITLE",
    SKELETON: "SKELETON",
    MODAL: "MODAL",
    MASONRY: "MASONRY",
    GRID_SECTIONS: "GRID_SECTIONS",
    NOTE_INPUT: "NOTE_INPUT",
    METRICS_CARD_SKELETON: "METRICS_CARD_SKELETON",
    METRICS_CARD: "METRICS_CARD",
    ERROR_WITH_RETRY: "ERROR_WITH_RETRY",
    ERROR_WITH_LOGOUT: "ERROR_WITH_LOGOUT",
    DROPDOWN_INPUT: "DROPDOWN_INPUT",
    ADD_TRADE_DIALOG: "ADD_TRADE_DIALOG",
    ADD_INDUSTRY_DIALOG: "ADD_INDUSTRY_DIALOG",
    TEXT_INPUT: "TEXT_INPUT",
    CHAT: "CHAT",
    CHAT_CHANNEL_PREVIEW: "CHAT_CHANNEL_PREVIEW",
    CHAT_CHANNEL_SEARCH: "CHAT_CHANNEL_SEARCH",
    CHAT_CHANNEL_SEARCH_RESULT: "CHAT_CHANNEL_SEARCH_RESULT",
    SEARCH: "SEARCH",
    DASHBOARD_RECENT_MESSAGE: "DASHBOARD_RECENT_MESSAGE",
    STATUS_SELECT: "STATUS_SELECT",
    LABEL_INPUT: "LABEL_INPUT",
    ACCOUNT_SETTINGS: "ACCOUNT_SETTINGS",
    SWITCH_COMPANY_BUTTON: "SWITCH_COMPANY_BUTTON",
    POST_SIGNUP_SKELETON: "POST_SIGNUP_SKELETON",
    PAGE_ERROR: "PAGE_ERROR",
    ATTACHMENT_PREVIEW: "ATTACHMENT_PREVIEW",
    SETTINGS_PAGE_SKELETON: "SETTINGS_PAGE_SKELETON",
    CHAT_SKELETON: "CHAT_SKELETON",
    COMPANY_INVITE_SCREEN: "COMPANY_INVITE_SCREEN",
    COMPANY_USER: "COMPANY_USER",
    COMPANY_USERS_MANAGEMENT_SKELETON: "COMPANY_USERS_MANAGEMENT_SKELETON",
    ACTIONABLE_BUTTON: "ACTIONABLE_BUTTON",
    AGREEMENT_SCROLL_CONTAINER: "AGREEMENT_SCROLL_CONTAINER",
    CANDIDATE_STATUS_INFO_TOOLTIP: "CANDIDATE_STATUS_INFO_TOOLTIP",
    SUGGESTED_CANDIDATE_CARD: "SUGGESTED_CANDIDATE_CARD",
    INVITE_TO_JOB_BUTTON: "INVITE_TO_JOB_BUTTON",
    IMPORT_JOBS_FROM_CSV_INPUT: "IMPORT_JOBS_FROM_CSV_INPUT",
    CONFIRMATION_MODAL: "CONFIRMATION_MODAL",
    TRANSLATE_BUTTON: "TRANSLATE_BUTTON",
    BOARD_COLUMN_SORT_BUTTON: "BOARD_COLUMN_SORT_BUTTON",
    STYLED_BOX_WITH_ACTION_BUTTON: "STYLED_BOX_WITH_ACTION_BUTTON",
    COMPANY_BULLETIN_BOARD: "COMPANY_BULLETIN_BOARD",
    COMPANY_CARDS: "COMPANY_CARDS",
    CSV_UPLOAD_INPUT: "CSV_UPLOAD_INPUT",
    SHARE_BUTTON: "SHARE_BUTTON",
    ADD_CIRCLE_OUTLINE_ICON: "AddCircleOutlineIcon",
    EDIT_OUTLINED_ICON: "EditOutlinedIcon",
    REMOVE_CIRCLE_OUTLINE_ICON: "RemoveCircleOutlineIcon",
    BULLETIN_BOARD_ERROR: "BULLETIN_BOARD_ERROR",
    BULLETIN_POST_MODAL: "BULLETIN_POST_MODAL",
    CONFIRMATION_MODAL_BUTTON: "CONFIRMATION_MODAL_BUTTON",
    CONTENT_SECTIONS_SKELETON: "CONTENT_SECTIONS_SKELETON",
    WORKER_MESSAGE: "WORKER_MESSAGE",
    HELP_MODAL_SIDEBAR: "HELP_MODAL_SIDEBAR",
    HELP_ICON: "HELP_ICON",
    CONTENT_BLOCK_IMAGE_PROGRESS_BAR: "CONTENT_BLOCK_IMAGE_PROGRESS_BAR",
    TABLE_BULK_ACTIONS_HEADER: "TABLE_BULK_ACTIONS_HEADER",
    TABLE_SELECT_ROW: "TABLE_SELECT_ROW",
    TABLE_SELECT_ALL_ROWS: "TABLE_SELECT_ALL_ROWS",
    TABLE_BULK_ACTION_BUTTON: "TABLE_BULK_ACTION_BUTTON",
    CANDIDATE_TRADES_POPOVER: "CANDIDATE_TRADES_POPOVER",
    QR_CODE_MODAL_CONTENT: "QR_CODE_MODAL_CONTENT",
    QR_CODE: "QR_CODE",
    JOB_CELL: "JOB_CELL",
    DUPLICATE_PROJECT_BUTTON: "DUPLICATE_PROJECT_BUTTON",
    SWITCH: "SWITCH",
    BACK_PILL_BUTTON: "BACK_PILL_BUTTON",
    CONTENT_BLOCK_SHARE_BUTTON: "CONTENT_BLOCK_SHARE_BUTTON",
    ENABLED_LINK_CHECKBOX: "ENABLED_LINK_CHECKBOX",
    ENABLED_LINK_INPUT: "ENABLED_LINK_INPUT",
    EDIT_NOTES_BUTTON: "NOTES_BUTTON",
    SAVE_NOTES_BUTTON: "SAVE_NOTES_BUTTON",
};

export const ARIA_LABELS = {
    ADDRESS_ERROR: "address",
};

export const THEME_MODE = {
    LIGHT: "light",
    DARK: "dark",
};

export const PAGES = {
    LANDING: "landing",
    SETTINGS: "settings",
    DASHBOARD: "dashboard",
    DEFAULT: "DEFAULT",
    POST_SIGNUP: "post-signup",
    JOBS: "jobs",
    JOB_DETAILS: "job-details",
    CANDIDATES: "candidates",
    MESSAGES: "messages",
    PARTNERS: "partners",
    PARTNER: "partner",
    PARTNER_EDIT: "partner-edit",
    COMPANY: "company",
    PROJECTS: "projects",
    PROJECT_DETAILS: "project-details",
    CONTENT_BLOCKS: "content-blocks",
    EDIT_CONTENT_BLOCKS: "content-blocks-edit",
    NEW_CONTENT_BLOCKS: "content-blocks-new",
    PEOPLE: "people",
    TEMPLATES: "templates",
    FORMS: "forms",
    CAMPAIGNS: "campaigns",
    NEW_CAMPAIGN: "new-campaign",
    CAMPAIGN_CANDIDATES: "campaign-entries",
};

export const PROPERTY_NAMES = {
    ANSWER: "answer",
};

export const FORM_FIELD_TYPES = {
    SHORT_ANSWER: "SHORT_ANSWER",
    PARAGRAPH: "PARAGRAPH",
    DROPDOWN: "DROPDOWN",
    MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
    CHECKBOXES: "CHECKBOXES",
    SIGNATURE: "SIGNATURE",
};

export const FORM_FIELD_LABELS_BY_TYPE = {
    [FORM_FIELD_TYPES.SHORT_ANSWER]: "Short Answer",
    [FORM_FIELD_TYPES.PARAGRAPH]: "Paragraph",
    [FORM_FIELD_TYPES.DROPDOWN]: "Dropdown",
    [FORM_FIELD_TYPES.MULTIPLE_CHOICE]: "Multiple Choice",
    [FORM_FIELD_TYPES.CHECKBOXES]: "Checkboxes",
    [FORM_FIELD_TYPES.SIGNATURE]: "Signature",
};

export const ROUTES = {
    SETTINGS: "/settings",
    DASHBOARD: "/",
    POST_SIGNUP: "/post-signup",
    JOBS: "/jobs",
    NEW_JOB: "/jobs/new",
    JOB_DETAILS: "/jobs/:id",
    CONFIRM_JOB_PURCHASE: "/jobs/:id/confirm-purchase",
    CANDIDATES: "/candidates",
    MESSAGES: "/messages",
    LANDING: "/landing",
    PARTNERS: "/partners",
    PARTNER: "/partners/:id",
    PARTNER_EDIT: "/partners/:id/edit",
    // todo: change company to partner
    COMPANY: "/company",
    PROJECTS: "/projects",
    PROJECT_DETAILS: "/projects/:id",
    EDIT_PROJECTS_CONTENT_BLOCK: "/projects/:id/content-block/edit",
    PEOPLE: "/people",
    CONTENT_BLOCKS: "/content-blocks",
    EDIT_CONTENT_BLOCKS: "/content-blocks/:id/edit",
    NEW_CONTENT_BLOCKS: "/content-blocks/new",
    TEMPLATES: "/templates",
    FORMS: "/forms",
    CAMPAIGNS: "/campaigns",
    NEW_CAMPAIGN: "/campaigns/new",
    CAMPAIGN_CANDIDATES: "/campaigns/:id",
};

export const TABLES = {
    EMPTY_ROWS: "EMPTY_ROWS",
    FIRST_CELL: "FIRST_CELL",
    TABLE_ROW: "TABLE_ROW",
    SORT: "SORT",
    BASIC: "BASIC",
    ERROR: "ERROR",
    ADD_ENTRY: "ADD_ENTRY",
};

export const TABLE_IDS = {
    JOBS_DASHBOARD: "JOBS_DASHBOARD",
    JOBS_COMPANY_INTERNAL: "JOBS_COMPANY_INTERNAL",
    JOBS_PAGE: "JOBS_PAGE",
    CAMPAIGN_RESPONSE_PAGE: "CAMPAIGN_RESPONSE_PAGE",
};

export const FORM_INPUTS = {
    BUTTON: "form-button",
    SECONDARY_BUTTON: "secondary-form-button",
    NAME: "name",
    ADDRESS: "address",
    PHONE: "phone",
    WEBSITE: "website",
    TRADE: "trade",
    ADDITIONAL_DETAILS: "additional_details",
    INDUSTRIES: "industries",
    FIRST_NAME: "first-name",
    LAST_NAME: "last-name",
    EMAIL: "email",
    BIO: "bio",
    COMPANY_NAME: "company-name",
    TYPE_OF_COMPANY: "type-of-company",
    LOCATION: "location",
    COMPANY_PHONE: "company-phone",
    COMPANY_PROFILE_IMAGE: "company-profile-image",
    COMPANY_COVER_IMAGE: "company-cover-image",
    DESCRIPTION: "description",
    ROLE: "role",
    CSV_UPLOAD: "csv-upload",
    OPTION_SELECTOR: "option-selector",
    INVITE_MESSAGE: "invite-message",
    SWITCH: "switch",
    COMPANY_ID: "company-id",
    CREATED_BY: "created-by",
    FORM_HEADER: "form-header",
    FORM_DESCRIPTION: "form-description",
    MORE_INFO_DESCRIPTION: "more-info-description",
    CAMPAIGN_FORM: "campaign-form",
    MORE_INFO_FORM: "more-info-form",
    ADD_LOGO: "add-logo",
    YEARS_OF_EXPERIENCE: "years-of-experience",
    IS_CURRENTLY_ATTENDING: "is-currently-attending",
    JOB_TITLE: "job-title",
    EMPLOYER: "employer",
    START_DATE: "start-date",
    END_DATE: "end-date",
    CURRENTLY_WORKING_HERE: "currently-working-here",
    SCHOOL: "school",
    DIPLOMA: "diploma",
    RESUME: "resume",
    COMPANY_PHOTOS: "company-photos",
};

export const FORM_LABELS = {
    NAME: "Name",
    LOCATION: "Location",
    DESCRIPTION: "Description",
    ROLE: "Role",
    PHONE_NUMBER: "Phone number",
    EMAIL: "Email",
    INVITE_MESSAGE: "Invite message",
    COMPANY_ID: "Company ID",
    CREATED_BY: "Created By",
    FORM_HEADER: "Per Diem Hunter",
    FORM_DESCRIPTION: "Form Description",
};

export const PROJECT_DESCRIPTION_LIMIT = 256;

export const FORM_ERROR = {
    FULL_ADDRESS: "Please enter full address",
    GOOGLE_PLACES:
        "An error occurred when trying to get addresses. Please try again.",
    PHONE: "Please enter a valid phone number",
    URL: "Please enter a valid url",
    NAME: "Please enter a valid name",
    REQUIRED: "Required",
    MIN_ONE: "Requires at least 1 selection",
    MAX_TEN_THOUSAND: "Please keep description under 10000 characters",
    MAX_TWO_HUNDRED: "Please keep questions under 200 characters",
    MAX_TWO_HUNDRED_FIFTY: "Please keep answers under 250 characters",
    NUMERIC_TYPE_ERROR: "Amount must be a number",
    NUMERIC_TYPE_ERROR_PLURAL: "Amounts must be numbers",
    EMAIL: "Please enter a valid email",
    JOB_DESCRIPTION: "Please provide a job description",
    JOB_POST_EXPIRATION: "Please select when this post will expire",
    JOB_POST_AGREEMENT: "Please read and accept Job Posting Agreement.",
    REQUIRED_NAME_IN_CSV: "The name column is missing in the provided CSV",
    REQUIRED_PHONE_IN_CSV: "The phone column is missing in the provided CSV",
    REQUIRED_EMAIL_IN_CSV: "The email column is missing in the provided CSV",
    EMAIL_IN_CSV: "Please provide valid emails in the CSV",
    PHONE_IN_CSV: "Please provide valid phone numbers in the CSV",
    MAX_TWO_HUNDRED_MESSAGE: "Please keep messages under 200 characters",
    MAX_DESCRIPTION: `Must be less than ${PROJECT_DESCRIPTION_LIMIT} characters`,
    INVALID_URL_PREFIX: "Url must start with http:// or https://",
};

export const FORM_CHAR_LIMIT = {
    JOB_DESCRIPTION: 10000,
};

export const TOOL_TIPS = {
    JOB_AGREEMENT_DISABLED:
        "Scroll all the way to the bottom in order to accept.",
};

export const SORT = {
    DESC: "desc",
    ASC: "asc",
};

export const HEIGHT = {
    FORM_INPUT: 48,
    SMALL_CARD: 162,
    XSMALL_CARD: 123,
    NAVBAR: 100,
    JOB_DESCRIPTION: 550,
};

export const WIDTH = {
    SMALL_CARD: 383,
};

export const IMG = {
    GOOGLE: {
        SRC: "https://developers.google.com/maps/documentation/images/powered_by_google_on_white.png",
        ALT: "Powered by Google",
    },
};

export const USER_INPUT = {
    CLICKAWAY: "clickaway",
    ENTER: "Enter",
};

export const LANGUAGES = {
    EN: "en",
    ES: "es",
};

export const DEFAULT_DEBOUNCE_TIMEOUT = 300;

export const SORT_ORDERS = {
    DESCENDING: "desc",
    ASCENDING: "asc",
};

export const DEFAULT_SEARCH_BY_NAME_SORT = {
    by: "name.keyword",
    order: SORT_ORDERS.ASCENDING,
};

export const DEBOUNCE_KEYS = {
    SEARCH_TRADES: "searchTrades",
    SEARCH_USERS: "searchUsers",
    GET_MY_JOBS: "getMyJobs",
    GET_JOB_CANDIDATES_BY_COMPANY: "getJobCandidatesByCompany",
    GET_COMPANY_JOBS: "getCompanyJobs",
    GET_COMPANIES: "getCompanies",
    GET_INDUSTRIES: "getIndustries",
    GET_PROJECTS: "getProjects",
    GET_FORMS: "getForms",
    GET_TEMPLATES: "getTemplates",
    GET_CAMPAIGNS: "getCampaigns",
    GET_ALL_CAMPAIGN_CANDIDATES: "getAllCampaignCandidates",
    SEARCH_PLACES_OF_EMPLOYMENT: "searchPlacesOfEmployment",
};

export const APOLLO_TYPE_NAMES = {
    TRADE: "Trade",
    ID_AND_NAME: "IdAndName",
};

export const ENTITY_TYPE = {
    FIXED: "fixed",
    RANGE: "range",
};

export const BOARD_STATUSES = {
    NEW: "NEW",
    INTERESTED: "INTERESTED",
    REJECTED: "REJECTED",
    HIRED: "HIRED",
};

export const BOARD_STATUS_LABELS = {
    NEW: "New",
    INTERESTED: "Interested",
    REJECTED: "Rejected",
    HIRED: "Hired",
};

export const FORM_TYPES = {
    ADD: "Add",
    EDIT: "Edit",
};

export const CDN_ACCESS_TYPES = {
    READ: "read",
    DELETE: "delete",
};

export const CDN_GRANTED_ENTITY_TYPES = {
    USER: "user",
    COMPANY: "company",
    ROLE: "role",
    EVERYONE: "everyone",
    ANY_COMPANY: "any_company",
};

export const USER_COMPANY_ROLES = {
    OWNER: "owner",
    EMPLOYER: "employer",
    WORKER: "worker",
};

export const AVATAR_VARIANTS = {
    SQUARE: "square",
};

export const IMAGE_VARIANTS = {
    THUMBNAIL: "thumbnail",
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large",
};

export const CERTIFICATION_STATUSES = {
    PENDING: "pending",
    VERIFIED: "verified",
};

export const DEFAULT_SORT = {
    by: "created_at",
    order: SORT.DESC,
};

export const SORT_BY_NAME = {
    by: "name",
    order: SORT.ASC,
};

export const SORT_BY_DATE_POSTED = {
    by: "posted_at",
    order: SORT.ASC,
};

export const DEFAULT_LIMITS = {
    COMPANY_USERS: 10,
    TRADES_DIALOG_INDUSTRIES: 50,
    PENDING_COMPANY_INVITATIONS: 10,
    SUGGESTED_CANDIDATES: 6,
    JOBS: 10,
    PLACES_OF_EMPLOYMENT: 5,
};

export const QUERY_REFETCH_DELAY = 1000;

export const LOGIN_TYPE = {
    EMAIL: "email",
    SMS: "sms",
};

export const HUBSPOT_ENTITY_TYPE = {
    COMPANY: "company",
};

export const COMPANY_STATUS = {
    NOT_ONBOARDED: "Not Onboarded",
    SUBSCRIPTION_EXPIRED: "Subscription Expired",
    ACTIVE: "Active",
};

export const ANALYTICS_EVENTS = {
    CANDIDATE_STATUS_CHANGED: "candidate_status_changed",
    SUGGESTED_CANDIDATES: "suggested_candidates",
    INVITE_TO_JOB: "invite_to_job",
    COMPANY_UPDATED: "company_updated",
    PROJECT_CREATED: "project_created",
    PROJECT_DUPLICATED: "project_duplicated",
    PROJECT_UPDATED: "project_updated",
    PROJECT_DELETED: "project_deleted",
    SHARE_CLICKED: "share_clicked",
    OPENED_SHARE_JOB_MODAL: "opened_share_job_modal",
    INVITED_USERS_TO_COMPANY: "invited_users_to_company",
    ACCEPTED_COMPANY_INVITATION: "accepted_company_invitation",
    DOWNLOADED_USERS_CSV: "downloaded_users_csv",
    GET_PROJECT_QR_CODE: "get_project_qr_code",
    GET_PROJECT_URL: "get_project_url",
    REQUEST_REFERRALS: "request_referrals",
    REFERRAL_REMOVED: "referral_removed",
    REFERRAL_HIRED: "referral_hired",
};

export const ANALYTICS_ENTITY_TYPES = {
    JOB: "job",
    CAMPAIGN: "campaign",
};

export const ANALYTICS_SHARED_ON = {
    LINKEDIN: "linkedin",
    TWITTER: "twitter",
    FACEBOOK: "facebook",
    EMAIL: "email",
};

export const SEVEN_DAYS_IN_SECONDS = 604800;

export const DAYS_UNTIL_CANDIDATE_EXPIRES = 18;

export const DAYS_UNTIL_REJECTED_CANDIDATE_IS_NOTIFIED = 3;

export const CANDIDATE_REJECTION_REASON = {
    CERTIFICATIONS: "Insufficient certifications",
    EXPERIENCE: "Experience level",
};

export const FORM_INPUT_TYPES = {
    STRING: "string",
    NUMBER: "number",
    DATE: "date",
    API_ENUM: "api_enum",
    MULTISELECT_ENUM: "multiselect_enum",
    ENUM: "enum",
    ATTACHMENT_URL: "attachment_url",
};

export const FORM_INPUT_VALUE_TYPES = {
    STRING: "string_value",
    DATE: "timestamp_value",
    ENTITY: "entity_value",
    ID_AND_NAME: "id_name_value",
    ID_AND_NAME_VALUES: "id_name_values",
};

// https://gitlab.com/boomnation/api-boomnation/-/feature_flags
export const FEATURE_FLAGS = {
    SHOW_COMPANY_PAGE: "show_company_page",
    // eslint-disable-next-line no-secrets/no-secrets
    SHOW_COMPANY_BULLETIN_POST_VIEWS: "show_company_bulletin_post_views",
    SHOW_PROJECT_BULLETIN_POST_VIEWS: "show_project_bulletin_post_views",
};

export const ENTITY_TYPES = {
    COMPANIES: "companies",
    PROJECTS: "projects",
    FORMS: "forms",
    BULLETIN_POSTS: "bulletin_posts",
    CONTENT_BLOCKS: "content_blocks",
    CAMPAIGN: "campaign",
};

export const CONTENT_BLOCK_ENTITY_TYPES_TO_CARD_TYPE = {
    [ENTITY_TYPES.COMPANIES]: "company",
    [ENTITY_TYPES.PROJECTS]: "project",
};

export const ENTITY_TYPE_TO_ENTITY_NAME = {
    [ENTITY_TYPES.PROJECTS]: "project",
};

export const COMPANY_FEATURES = {
    COMPANY_CARDS: "company_cards",
    COMPANY_BULLETIN: "company_bulletin",
    PROJECT_CARDS: "project_cards",
    PROJECT_BULLETINS: "project_bulletins",
    HIRING: "hiring",
    PROJECT_REFERRALS: "project_referrals",
};

export const FEATURE_GROUPS = {
    COMPANY: "company",
    PROJECTS: "projects",
    HIRING: "hiring",
};

export const USERS_CSV_FILE_NAME = "users.csv";

export const CAMPAIGN_RESPONSES_CSV_FILE_NAMES = {
    GET_CAMPAIGN_RESPONSES_CSV_FILE_NAME: "campaignResponses.csv",
    GET_ALL_CAMPAIGN_RESPONSES_CSV_FILE_NAME: "allCampaignResponses.csv",
};

export const CAMPAIGN_RESPONSES_CSV_COLUMN_NAMES = {
    NAME: "Name",
    DATE_SUBMITTED: "Date Submitted",
    PHONE: "Phone Number",
    EMAIL: "Email",
    STATUS: "Status",
};

export const ACCESS_ERROR_MESSAGE = "This feature is not available right now";

export const IMAGE_UPLOAD_ERROR =
    "There was an error uploading your images. Please try again.";

export const QUERY_PARAMS = {
    ENTITY_ID: "entity_id",
    ENTITY_TYPE: "entity_type",
    COMPANY_ID: "company_id",
    CODE: "code",
};

export const ACCOUNT_SETTINGS_STEP_IDS = {
    ACCOUNT_INFORMATION: "account_information",
    PERMISSIONS: "permissions",
    BILLING: "billing",
    COMPANY_PAGE: "company_page",
};

export const ACCOUNT_SETTINGS_TABS = {
    ACCOUNT_INFORMATION: "Account Information",
    BILLING: "Billing",
    COMPANY_PAGE: "Company Page",
};

export const IMAGE_URL =
    // Image Link leads to boomnation logo from the App store.
    // eslint-disable-next-line no-secrets/no-secrets
    "https://is2-ssl.mzstatic.com/image/thumb/Purple113/v4/c7/37/81/c73781fd-c7fc-87f1-8f2d-60a5c8e62662/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/1200x600wa.png";

export const POLICY_PAGES = {
    JOB_POSTING_AGREEMENT:
        "https://www.boomnation.com/pages/job-posting-agreement",
};

export const QR_CODE_SIZE = 300;

export const BULLETIN_BOARD_HEIGHT = "50rem";

export const PAY_VALIDATION = {
    MIN: 0,
    MAX: 100,
};

export const PER_DIEM_VALIDATION = {
    MIN: 0,
    MAX: 250,
};

export const GENERATE_ZIP_URL = `${process.env.CDN_LINKS_URL}/generate/zip`;

export const BULLETIN_POST_VIEWS_TEST_ID = "BULLETIN_POST_VIEWS";

export const CONTENT_SECTIONS_MENU_CONFIG_FLAGS = {
    share: true,
    delete: true,
    edit: true,
    save_as_template: true,
};

export const PROJECT_ACCESS_TYPES = {
    PUBLIC: "public",
    PRIVATE: "private",
    INTERNAL: "internal",
};

export const COMPANY_ADDRESS_PLACEHOLDER = "123 Broadway Street, Nashville, TN";

export const CAMPAIGN_TYPES = {
    RECRUITING: "recruiting",
    MARKETING: "marketing",
};

export const CAMPAIGN_FORM_TYPES = {
    RECRUITING: "recruiting_campaign",
    GENERAL: "general_campaign",
};
