import { gql } from "@apollo/client";

import { USER_COMPANY_ROLE_RELATIONSHIP_PROPS } from "~graphql/schemas/userCompanyRoleRelationship";

export const REMOVE_USER_FROM_COMPANY = gql`
    mutation Mutation($companyId: UUID!, $userId: String!) {
        removeUserFromCompany(companyId: $companyId, userId: $userId)
    }
`;

export const REMOVE_USERS_FROM_COMPANY = gql`
    mutation Mutation($companyId: UUID!, $userIdList: [String!]!) {
        removeUsersFromCompany(companyId: $companyId, userIdList: $userIdList)
    }
`;

export const REMOVE_USERS_FROM_COMPANY_ENTITY = gql`
    mutation Mutation(
        $companyId: UUID!
        $entityId: UUID!
        $userIdList: [String!]!
    ) {
        removeUsersFromCompanyEntity(
            companyId: $companyId
            entityId: $entityId
            userIdList: $userIdList
        )
    }
`;

export const UPDATE_COMPANY_USER_ROLE = gql`
    mutation Mutation($companyId: UUID!, $userId: String!, $role: String!) {
        updateCompanyUserRoleRelationship(
            companyId: $companyId
            userId: $userId
            role: $role
        ) {
            ${USER_COMPANY_ROLE_RELATIONSHIP_PROPS}
        }
    }
`;

export const COMPANY_USER_FRAGMENT = gql`
    fragment CompanyUserFragment on CompanyUser {
        role
    }
`;
