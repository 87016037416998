import { isUndefined } from "@boomnation/lib-common";
import { useEffect, useState } from "react";
import { useChatContext } from "stream-chat-react";

import { useMessagingUserId } from "~app/navigationHelpers";
import { useConnectedStreamUser } from "~app/reactiveVars";
import { useCurrentCompany } from "~graphql/hooks/currentCompany";
import { useCompanyChatToken } from "~graphql/hooks/users";
import { logError } from "~services/sentry";
import {
    connectStreamUser,
    getClient,
    createChat,
    getDashboardChannelsAndRecentMessage,
    getStreamCompanyId,
} from "~services/streamHelpers";

// https://getstream.io/chat/docs/react/instantiating_the_client/?language=javascript
export const useChat = () => {
    const streamClient = getClient();
    const isInitConnected = Boolean(streamClient.userID);

    const [isConnected, setIsConnected] = useState(isInitConnected);
    const [error, setError] = useState(null);

    const { companyId, companyName } = useCurrentCompany();

    const { chatToken } = useCompanyChatToken({ companyId });
    const shouldConnectUser = companyId && chatToken && !isConnected;

    useEffect(() => {
        if (shouldConnectUser) {
            connectUserToStream({
                companyId,
                companyName,
                chatToken,
                setIsConnected,
                setError,
            });
        }
    }, [companyId, chatToken, shouldConnectUser, companyName]);

    const streamCompanyId = getStreamCompanyId(companyId);

    return { isConnected, error, streamCompanyId };
};

export function useUnreadMessagesCount() {
    const streamClient = getClient();

    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

    const { isConnected } = useChat();
    const connectedStreamUser = useConnectedStreamUser();

    // Set initial unread count from connected stream user.
    useEffect(() => {
        if (!connectedStreamUser || !isConnected) return;

        setUnreadMessagesCount(connectedStreamUser.me?.total_unread_count || 0);
    }, [connectedStreamUser, isConnected]);

    // Add/remove unread messages count event handler on mount/unmount.
    useEffect(() => {
        const handler = streamClient.on((event) => {
            if (isUndefined(event.total_unread_count)) return;

            setUnreadMessagesCount(event.total_unread_count);
        });

        return () => handler.unsubscribe();
    }, []);

    return unreadMessagesCount;
}

export const useChatChannel = ({ messagingUserId }) => {
    // Use chat to make sure we connect to stream
    const { isConnected } = useChat();
    const { companyId } = useCurrentCompany();
    const [activeChannel, setActiveChannel] = useState(null);
    const [error, setError] = useState(null);

    // Calling `setActiveChannel` from `useChatContext` is needed so
    // unread counts are accurate in the channelpreview component
    const { setActiveChannel: streamSetActiveChannel } = useChatContext();

    useEffect(() => {
        if (!messagingUserId || !companyId || !isConnected) {
            return;
        }
        createOrActivateChannel({
            companyId,
            messagingUserId,
            onSuccess: (channel) => {
                setActiveChannel(channel);

                // Why is this sometimes null?
                if (streamSetActiveChannel) {
                    streamSetActiveChannel(channel);
                }
            },
            onError: setError,
        });
    }, [companyId, messagingUserId, isConnected, streamSetActiveChannel]);

    return {
        activeChannel,
        clearActiveChannel: () => {
            setActiveChannel(null);
            if (streamSetActiveChannel) {
                streamSetActiveChannel(null);
            }
        },
        error,
    };
};

export const useActiveChatChannel = () => {
    const [messagingUserId] = useMessagingUserId();

    return useChatChannel({ messagingUserId });
};

export const useDashboardRecentMessages = ({ isConnected, limit, offset }) => {
    const { companyId } = useCurrentCompany();
    const [channels, setChannels] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (companyId && isConnected) {
            getDashboardChannelsAndMessage({
                companyId,
                onSuccess: setChannels,
                onError: setError,
                limit,
                offset,
            });
        }
    }, [companyId, isConnected, offset]);

    return { channels, error };
};

async function createOrActivateChannel({
    companyId,
    messagingUserId,
    onSuccess,
    onError,
}) {
    try {
        const channel = await createChat({
            companyId,
            messagingUserId,
        });

        onSuccess(channel);
    } catch (error) {
        onError(error);
    }
}

async function connectUserToStream({
    companyId,
    companyName,
    chatToken,
    setIsConnected,
    setError,
}) {
    try {
        await connectStreamUser({ companyId, companyName, chatToken });

        setIsConnected(true);
    } catch (error) {
        setError(error);
        logError({ error });
    }
}

async function getDashboardChannelsAndMessage({
    companyId,
    onSuccess,
    onError,
    limit,
    offset,
}) {
    try {
        const channel = await getDashboardChannelsAndRecentMessage({
            companyId,
            limit,
            offset,
        });
        onSuccess(channel);
    } catch (error) {
        logError({ error });
        onError(error);
    }
}
