export function getSortWithKeywordSuffix({
    keywordColumns,
    hasSearchKeyword,
    sort,
}) {
    const sortWithPrefix =
        hasSearchKeyword && keywordColumns.includes(sort.by)
            ? {
                  ...sort,
                  by: `${sort.by}.keyword`,
              }
            : sort;

    return sortWithPrefix;
}

export function getMoreEntities({ entities, total, isLoading, fetchMore }) {
    if (total === entities?.length || isLoading) return;

    fetchMore({ variables: { offset: entities.length } });
}
