import { useTheme } from "@boomnation/lib-web-common";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export function useScreenSize() {
    const { breakpoints } = useTheme();
    const isSmScreen = useMediaQuery(breakpoints.down("sm"));
    const isMdScreen = useMediaQuery(breakpoints.down("md"));
    const isStreamMdScreen = useMediaQuery(breakpoints.down("streamMd"));
    const isLargeScreen = useMediaQuery(breakpoints.up("md"));

    return { isSmScreen, isMdScreen, isStreamMdScreen, isLargeScreen };
}

export function useIsDarkMode() {
    const theme = useTheme();

    return theme.palette.isDarkMode;
}

export function useLocationQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

// Defaults to empty object for safe destructuring.
export function useLocationState() {
    const { state } = useLocation();

    return state || {};
}
