import {
    useLazyQuery as useLazyQueryOG,
    useQuery as useQueryOG,
    useMutation as useMutationOG,
} from "@apollo/client";
import { useSnackBar } from "@boomnation/lib-web-common";
import { useTranslation } from "react-i18next";

export const DEFAULT_MUTATION_CONFIGS = { showSnackbarOnError: true };

export const DEFAULT_QUERY_CONFIGS = { showSnackbarOnError: false };

export function useMutation(mutation, configs = {}) {
    const { showError } = useSnackBar();

    return useMutationOG(
        mutation,
        setConfigs({
            configs,
            defaultConfigs: DEFAULT_MUTATION_CONFIGS,
            showError,
        })
    );
}

export function useQuery(query, configs = {}) {
    const { showError } = useSnackBar();

    return useQueryOG(
        query,
        setConfigs({
            configs,
            defaultConfigs: DEFAULT_QUERY_CONFIGS,
            showError,
        })
    );
}

export function useLazyQuery(lazyQuery, configs = {}) {
    const { showError } = useSnackBar();

    return useLazyQueryOG(
        lazyQuery,
        setConfigs({
            configs,
            defaultConfigs: DEFAULT_QUERY_CONFIGS,
            showError,
        })
    );
}

function setConfigs({ configs, defaultConfigs, showError }) {
    const { boomnation, ...cleanConfigs } = configs;
    const mergedBnConfigs = { ...defaultConfigs, ...boomnation };

    return {
        ...(mergedBnConfigs.showSnackbarOnError && {
            onError: (error) => showError({ error }),
        }),
        ...cleanConfigs,
    };
}

export function useHandleOnCompleted({ successMessage, onCompleted }) {
    const { showSuccess } = useSnackBar();
    const { t } = useTranslation();

    const handleOnCompleted = () => {
        showSuccess({ message: t(successMessage) });
        onCompleted?.();
    };

    return handleOnCompleted;
}
