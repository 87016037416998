import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { COPY } from "~app/copyConstants";
import { removeChat } from "~services/hubspot";
import { logError } from "~services/sentry";
import { disconnectStreamUser } from "~services/streamHelpers";

import { TEST_IDS } from "~constants";

export default function LogoutButton({ isMenuItem, ...props }) {
    const { logout } = useAuth0();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    if (isMenuItem) {
        return (
            <MenuItem onClick={() => doLogout({ logout, setIsLoading })}>
                {t(COPY.LOGOUT.EN)}
            </MenuItem>
        );
    }

    return (
        <SmallLogoutButton
            data-testid={TEST_IDS.LOGOUT}
            loading={isLoading}
            disabled={isLoading}
            onClick={() => doLogout({ logout, setIsLoading })}
            {...props}
        >
            {t(COPY.LOGOUT.EN)}
        </SmallLogoutButton>
    );
}

LogoutButton.propTypes = {
    isMenuItem: PropTypes.bool,
};

const SmallLogoutButton = styled(LoadingButton)({
    width: "75px",
    margin: "10px",
});

async function doLogout({ setIsLoading, logout }) {
    setIsLoading(true);

    try {
        localStorage.clear();
        await disconnectStreamUser();
        removeChat();
        logout({ returnTo: window.location.origin });
    } catch (error) {
        logError({ error });
    }
}
