import styled from "@emotion/styled";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";

import { useCurrentCompany } from "~graphql/hooks/currentCompany";
import CompanyAvatar from "~shared/Avatar/CompanyAvatar";
import LogoutButton from "~shared/Button/LogoutButton";
import NavBarSwitchCompanyMenu from "~shared/NavBar/NavBarSwitchCompanyMenu";

const anchorOrigin = {
    vertical: "bottom",
    horizontal: "right",
};

const transformOrigin = {
    vertical: "top",
    horizontal: "right",
};

export default function NavBarMenuButton() {
    const { companyName, companyId } = useCurrentCompany();

    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <NavBarProfileButton
                variant="text"
                color="secondary"
                disableElevation
                onClick={handleClick}
                startIcon={<CompanyAvatar size={35} id={companyId} />}
                icon={<KeyboardArrowDownIcon />}
            >
                <Typography variant="body2">{companyName}</Typography>
            </NavBarProfileButton>
            <StyledMenu
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleClose}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            >
                <NavBarSwitchCompanyMenu />
                <LogoutButton isMenuItem />
            </StyledMenu>
        </Box>
    );
}

const NavBarProfileButton = styled(Button)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    color: theme.palette.text.primary,
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
    "& .MuiMenu-list": {
        padding: 0,
        minWidth: 200,
        zIndex: theme.zIndex.modal,
    },
}));
