import { DEFAULT_LIMITS, DEFAULT_SORT } from "~app/constants";
import {
    readQuery,
    writeQuery,
    addEntityOrEntitiesToPaginatedQuery,
    removeEntitiesFromPaginatedQuery,
} from "~graphql/cache/shared";
import {
    GET_COMPANY,
    GET_COMPANIES,
    GET_COMPANY_INVITATIONS,
    GET_PENDING_COMPANY_INVITATIONS,
} from "~graphql/queries/companies";

const DEFAULT_PENDING_INVITATIONS_VARIABLES = {
    limit: DEFAULT_LIMITS.PENDING_COMPANY_INVITATIONS,
    offset: 0,
    sort: DEFAULT_SORT,
    searchKeyword: null,
};

export function addCompanyToCache({ cache, createdCompany }) {
    const variables = { id: createdCompany.id };

    writeQuery({
        ...getDefaultCompanyCacheQueryArgs({ cache, variables }),
        update: createdCompany,
    });
}

export function addCompanyToCompaniesCache({ cache, createdCompany }) {
    const getCompanies = getCompaniesFromCache({ cache });

    const currentResults = getCompanies?.results || [];
    const currentTotal = getCompanies?.meta?.total || 0;

    const results = [createdCompany, ...currentResults];
    const meta = { total: currentTotal + 1 };

    writeToCompaniesCache({ cache, results, meta });
}

export function removeCompanyFromCompaniesCache({ cache, id }) {
    removeEntitiesFromPaginatedQuery({
        ...getDefaultCompaniesCacheQueryArgs({ cache }),
        entityIdList: [id],
    });
}

export function updateCompanyCache({ cache, updatedCompany }) {
    const variables = {
        id: updatedCompany.id,
    };

    updateCompanyCacheCore({ cache, variables, update: updatedCompany });
}

export function removeCompanyInvitationCache({ cache, invitationId, code }) {
    const invitations = readQuery({
        cache,
        query: GET_COMPANY_INVITATIONS,
        pathToData: "getCompanyInvitations",
        variables: { code },
    });

    const invitationsWithoutAccepted = invitations.filter(
        (invitation) => invitation.id !== invitationId
    );

    cache.writeQuery({
        query: GET_COMPANY_INVITATIONS,
        data: {
            getCompanyInvitations: invitationsWithoutAccepted,
        },
    });
}

export function addToPendingCompanyInvitationsCache({
    cache,
    companyId,
    invitations,
    entityId,
    entityIdList,
}) {
    addToPendingCompanyInvitationsCacheCore({
        cache,
        companyId,
        invitations,
        entityIdList: entityIdList || [entityId],
    });
}

export function removeFromPendingCompanyInvitationsCache({
    cache,
    entityId,
    invitation,
}) {
    removeEntitiesFromPaginatedQuery({
        cache,
        query: GET_PENDING_COMPANY_INVITATIONS,
        variables: {
            companyId: invitation.company_id,
            entityId,
            ...DEFAULT_PENDING_INVITATIONS_VARIABLES,
        },
        pathToData: "getPendingCompanyInvitations",
        entityIdList: [invitation.id],
    });
}

function addToPendingCompanyInvitationsCacheCore({
    cache,
    companyId,
    invitations,
    entityIdList,
}) {
    entityIdList.forEach((entityId) =>
        addEntityOrEntitiesToPaginatedQuery({
            cache,
            query: GET_PENDING_COMPANY_INVITATIONS,
            variables: {
                companyId,
                entityId,
                ...DEFAULT_PENDING_INVITATIONS_VARIABLES,
            },
            pathToData: "getPendingCompanyInvitations",
            entityOrEntities: invitations,
            shouldAddToBeginning: true,
        })
    );
}

function updateCompanyCacheCore({ cache, variables, update }) {
    const existingCompany = getCompanyFromCache({ cache, variables });
    const updatedCompany = { ...existingCompany, ...update };

    writeQuery({
        ...getDefaultCompanyCacheQueryArgs({ cache, variables }),
        update: updatedCompany,
    });
}

function getCompanyFromCache({ cache, variables }) {
    const queryArgs = getDefaultCompanyCacheQueryArgs({ cache, variables });

    return readQuery(queryArgs);
}

function getCompaniesFromCache({ cache }) {
    const queryArgs = getDefaultCompaniesCacheQueryArgs({ cache });

    return readQuery(queryArgs);
}

function writeToCompaniesCache({ cache, results, meta }) {
    const queryArgs = getDefaultCompaniesCacheQueryArgs({ cache });

    writeQuery({ ...queryArgs, update: { results, meta } });
}

function getDefaultCompanyCacheQueryArgs({ cache, variables }) {
    return {
        cache,
        variables,
        query: GET_COMPANY,
        pathToData: "getCompany",
    };
}

function getDefaultCompaniesCacheQueryArgs({ cache }) {
    return {
        cache,
        variables: {
            offset: 0,
            searchKeyword: null,
        },
        query: GET_COMPANIES,
        pathToData: "getCompanies",
    };
}
