import PropTypes from "prop-types";
import React, { createContext, useState, useContext } from "react";

const TableStateStoreContext = createContext();

// TODO validate table state against table args spec
export const useTableState = (tableId) => {
    const { state, setState } = useContext(TableStateStoreContext);

    const tableState = state[tableId] || {};
    const setTableState = (newTableState) =>
        setState((currState) => ({ ...currState, [tableId]: newTableState }));

    return [tableState, setTableState];
};

export default function TableStateProvider({ children }) {
    const [state, setState] = useState({});

    return (
        <TableStateStoreContext.Provider
            value={{
                state,
                setState,
            }}
        >
            {children}
        </TableStateStoreContext.Provider>
    );
}

TableStateProvider.propTypes = {
    children: PropTypes.any.isRequired,
};
