import PropTypes from "prop-types";
import React, { memo, useContext } from "react";

import { FEATURE_FLAGS } from "~app/constants";
import { useFeatureFlagPolling } from "~graphql/hooks/featureFlags";

const FeatureFlagContext = React.createContext();

export const useFeatureFlags = () => useContext(FeatureFlagContext);

function FeatureFlagProvider({ children }) {
    const { featureFlags, isLoading, error } = useFeatureFlagPolling();

    return (
        <FeatureFlagContext.Provider value={{ featureFlags, isLoading, error }}>
            {children}
        </FeatureFlagContext.Provider>
    );
}

export default memo(FeatureFlagProvider);

FeatureFlagProvider.propTypes = {
    children: PropTypes.any,
};

export function useIsFeatureFlagEnabled(flagKey) {
    const { featureFlags } = useFeatureFlags();

    return featureFlags?.[flagKey] === true;
}

export function useShouldShowProjectBulletinPostViews() {
    return useIsFeatureFlagEnabled(
        FEATURE_FLAGS.SHOW_PROJECT_BULLETIN_POST_VIEWS
    );
}

export function useShouldShowCompanyBulletinPostViews() {
    return useIsFeatureFlagEnabled(
        FEATURE_FLAGS.SHOW_COMPANY_BULLETIN_POST_VIEWS
    );
}

export function useShouldShowCompanyPage() {
    return useIsFeatureFlagEnabled(FEATURE_FLAGS.SHOW_COMPANY_PAGE);
}
